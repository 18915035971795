import InboxStore from "./InboxStore";
import MailStore from "./MailStore";
import MailFormStore from "./MailFormStore"
import TagManagerStore from "./TagManagerStore";
import TagManagerContactStore from "./Contact/TagManagerContactStore";
import MailSearchStore from "./MailSearchStore";
import PublicGroupManagerStore from "./PublicGroupManagerStore";
import PublicGroupManagerContactStore from "./Contact/PublicGroupManagerContactStore";


class Mail {
    constructor() {
        this.inboxStore = new InboxStore(this)
        this.mailStore= new MailStore()
        this.mailFormStore = new MailFormStore()
        this.tagManagerContactStore = new TagManagerContactStore(this)
        this.tagManagerStore = new TagManagerStore(this)
        this.mailSearchStore = new MailSearchStore(this)
        this.publicGroupManagerStore = new PublicGroupManagerStore(this)
        this.publicGroupManagerContactStore = new PublicGroupManagerContactStore(this)
    }}

export default Mail
