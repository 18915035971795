import { action, makeObservable, observable } from "mobx";

class DialogStore {

  constructor() {
    makeObservable(this)
    this.name= 'dialogStore'
  }

  @observable confirmOpen = false
  @observable alertOpen = false
  @observable title = ''
  @observable content = ''
  @observable closeHandler = () => Promise.resolve()
  @observable okHandler = () => Promise.resolve()
  @observable confirmHandler = () => Promise.resolve()
  @observable cancelHandler = () => Promise.resolve()

  @action isConfirmOpen = (open) => {
    this.confirmOpen = open
  }

  @action isAlertOpen = (open) => {
    this.alertOpen = open
  }

  @action setTitle = (value) => {
    this.title = value
  }

  @action setContent = (value) => {
    this.content = value
  }

  @action setCloseHandler = (value) => {
    this.closeHandler = value
  }

  @action setOkHandler = (value) => {
    this.okHandler = value
  }

  @action setCancelHandler = (value) => {
    this.cancelHandler = value
  }

  @action setCommon = (title, content, closeHandler) => {
    this.setTitle(title)
    this.setContent(content)
    this.setCloseHandler(() => {
      closeHandler && closeHandler()
      this.closeAlert()
      this.closeConfirm()
    })
  }

  @action showConfirm = ({title, content, okHandler, cancelHandler , closeHandler}) => {
    this.setCommon(title, content, closeHandler)
    this.isConfirmOpen(true)

    this.setOkHandler(() => {
      okHandler()
      this.closeConfirm()
    })

    this.setCancelHandler(() => {
      cancelHandler && cancelHandler()
      this.closeConfirm()
    })
  }

  @action showAlert = ({title, content, okHandler}) => {
    this.isAlertOpen(true)
    this.setCommon(title, content, this.closeAlert)
    this.setOkHandler(() => {
      okHandler && okHandler()
      this.closeAlert()
    })
  }

  @action closeConfirm = () => {
    this.isConfirmOpen(false)
  }

  @action closeAlert = () => {
    this.isAlertOpen(false)
  }

}

export default DialogStore


