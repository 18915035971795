import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import NormalizedStore from 'src/store/NormalizedStore';
import { FIRST_PAGE, RESULT_ERROR, RESULT_SUCCESS } from 'src/constants/AppConst';
import { message } from 'src/constants/ResourceBundle';
import React from 'react';
import { searchMode } from 'src/views/address/AddressView/enums';

class AddressStore extends NormalizedStore {

  constructor() {
    super()
    makeObservable(this)
  }

  @observable addrSorts = []
  @observable addrDirections = []
  @observable isAddGroup = false
  @observable selectedGroup = {}
  @observable selectedOneAddress = {}
  @observable continueContents = []
  @observable searchMode = searchMode.USERS
  // @observable isEditAddrDialogOpen = { open:false, type: dialogType }
  @observable isEditAddrDialogOpen = false

  @action changePage = () => {
    if(!this.last && this.countContinueContents && this.countContinueContents !== 0) {
      this.page = (this.page + 1)
    }
  }


  @computed get lastGroupContent() {
    return { id: this.contents[0].id, name: this.contents[0].name }
  }


  @computed get countContinueContents() {
    return this.continueContents.length
  }

  @action setContinueContents = (content) => {
    this.continueContents = content
    // this.continueContents.push(content)
  }

  @action setSearchMode = (param) => {
    this.searchMode = param
  }

  @action setContinueContentsPush = (content) => {
    this.continueContents.push(...content)
  }

  @action initCurrentSelectedGroup(param) {
    this.setContinueContents([])
  }

  @action setAddrPage = (page) => {this.addrPage = page}

  @action setAddrPageSize = (pageSize) => {this.addrPageSize = pageSize}

  @action setAddrLoading = (addrLoading) => {this.addrLoading = addrLoading}

  @action setIsAddGroup = (isAdded) => {this.isAddGroup = isAdded}

  @action initContent = () => {
    this.setContinueContents([])
    this.addressPage = FIRST_PAGE
  }


  @action setSelectedGroup = (id, name) => {
    this.initContent()
    this.selectedGroup = {id:id, name: name}
  }

  @action setSelectedOneAddress = (address) => {this.selectedOneAddress = address}

  @action setIsEditAddrDialogOpen = (isOn) => {
    this.isEditAddrDialogOpen = isOn
  }


  @action saveAddress = async (address = {}) => {
    this.setAddrLoading(true)
    let result = {message: message('MSG_SAVE_SUCCESS'), status: RESULT_SUCCESS, result: true}
    await axios.post('/api/address/', address)
      .then(() => {
        this.initContent()
      })
      .catch((error) => {
        const {message} = error['response']['data']
        result = {status: RESULT_ERROR, message, result: false}
      })
      .finally(() => {
        this.setAddrLoading(false)
      })
    return result
  }

  @action deleteAddressById = (users, successHandler, failHandler) => {
    this.setAddrLoading(true)
    axios.post('/api/address/deletes', users)
      .then(res => {
        successHandler()
      })
      .catch(error => {
        failHandler()
      })
      .finally(() => {
        this.setAddrLoading(false)
      })
  }

  @action getAddressByGroupIdAndLike = (params = {}, successHandler, failHandler = () => {}) => {
    console.log('getAddressByGroupIdAndLike')
    this.sortedRequestTemplate(
      '/api/address',
      params,
      (res) => {
        this.setContents(res)
        successHandler(res)
      },
      (error) => {
        console.debug('fail : ', error)
        failHandler(error)
      }
    )
  }

  @action getUsersWithAddressGroup = (params = {}, successHandler = () => {}) => {
    this.sortedRequestTemplate(
      '/api/users',
      params,
      (res) => {
        this.setContents(res)
        successHandler(res)
      })
  }
}
export default AddressStore
