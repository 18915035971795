import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const CompanyRoute = [
  {
    path: '/app/management/company',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/management/company/new',
        component: lazy(() => import('src/views/company/CompanyEditView'))
      },
      {
        exact: true,
        path: '/app/management/company/edit/:id',
        component: lazy(() => import('src/views/company/CompanyEditView'))
      },
      {
        exact: true,
        path: '/app/management/company/detail/:id',
        component: lazy(() => import('src/views/company/CompanyEditView'))
      },
      {
        exact: true,
        path: '/app/management/company',
        component: lazy(() => import('src/views/company/CompanyListView'))
      },

    ]
  }
]

export default CompanyRoute
