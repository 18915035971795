import { action, makeObservable } from 'mobx';
import axios from 'axios';
import NormalizedStore from 'src/store/NormalizedStore';
import { paramParser } from '../../utils/paramParser';

class SubMenuStore extends NormalizedStore {

  constructor() {
    super()
    makeObservable(this)
    this.baseUrl = '/api/common/'
  }

  @action getMenus2 = (menu, params = {}) => {
    this.setLoading(true)
    console.debug('substore', params)
    axios.get(`${this.baseUrl}menu/sub?${paramParser(params)}`)
      .then(res => {
        this.setContents(res)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @action saveMenu = (menu, successHandler, failHandler) => {
    this.setLoading(true)
    axios.post(`${this.baseUrl}menu`, menu)
      .then(res => {
        successHandler()
      })
      .catch(error => {
        failHandler()
      })
      .finally(() => this.setLoading(false))
  }

  @action deletesMenus = (menu, successHandler, failHandler) => {
    this.setLoading(true)
    axios.post(`${this.baseUrl}menu/deletes`, menu)
      .then(res => {
        successHandler()
      })
      .catch(error => {
        failHandler()
      })
      .finally(() => this.setLoading(false))
  }

}
export default SubMenuStore