import {action, computed, makeObservable, observable} from 'mobx';
import NormalizedStore from "src/store/NormalizedStore";
import {ColumnType} from "src/constants/AppConst";

class PublicGroupManagerStore extends NormalizedStore {
  constructor(root) {
    super('id')
    makeObservable(this)
    this.root = root
  }

  searchCategories = [
    {
      label: ['userid'],
      field: 'userid',
      type: ColumnType.STRING
    },
    {
      label: ['name'],
      field: 'username',
      type: ColumnType.STRING,
      default: true
    },
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      width: 70,
    },
    {
      cellId: 'username',
      label: ['name'],
      useSort: true,
    },
    {
      cellId: 'userDetail.celno',
      label: ['celno'],
      width: 150,

    },
  ]

  @observable publicGroupList = []
  @observable addPublicGroupDialogIsOpen = false
  @observable selectedPublicGroup = {}
  @observable parentPublicGroup = {}
  @observable dialogType = ''

  @computed get testComputed() {
    return this.root.mailFormStore.getIsOpenContactDialog
  }

  @computed get getParentPublicGroup() {
    return this.parentPublicGroup
  }

  @computed get getPublicGroupNames() {
    const getPublicGroupTitle = (arr) => {
      const publicGroupNames = []
      arr.forEach(publicGroup => {
        publicGroupNames.push(publicGroup.title)
        !_.isNil(publicGroup.children) && getPublicGroupTitle(publicGroup.children)
      })
      return publicGroupNames
    }

    if(_.isNil(this.publicGroupList)) { return []}
    return getPublicGroupTitle(this.publicGroupList)
  }

  validateName = (pName) => {
    if(_.isNil(pName)) { return false}
    return -1 === this.getPublicGroupNames.findIndex(item => item === pName)
  }

  getUserBySelectedPublicGroup = (param) => {
    this.selectedPublicGroup.id && this.getUsersByPublicGroup(this.selectedPublicGroup.id, param)
  }

  @computed get getSelectedPublicGroup () {
    console.debug('selectedPublicGroup', this.selectedPublicGroup)
    return this.selectedPublicGroup;
  }


  @action setSelectedPublicGroup = (param = {}) => {
    console.debug('selected', param)
    this.selectedPublicGroup = param
  }

  @action openAddPublicGroupDialog = (item = {}, type) => {
    this.parentPublicGroup = item
    this.dialogType = type
    this.addPublicGroupDialogIsOpen = true
  }

  @action closeAddPublicGroupDialog = () => {
    this.parentPublicGroup = {}
    this.dialogType = ''
    this.addPublicGroupDialogIsOpen = false
  }

  @action getPublicGroupList = () => {
    this.getRequestTemplate(`/api/public-group/all`, null , (res) => {
      this.publicGroupList = res.data
    })
  }

  @action getUsersByPublicGroup = (pPublicGroupId, param = {}) => {
    console.debug('param', param)
    const req = {
      ...param,
      searchCategory: this.searchCategory ? this.searchCategory.split(':')[0] : '',
      searchValue: this.searchValue || '',
    }

    this.sortedRequestTemplate(`/api/public-group/${pPublicGroupId}/users`, req, (res) => {
      this.setContents(res)
    })
  }

  @action removePublicGroup = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.deleteRequestTemplate(`/api/public-group/delete/${param}`, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action removeUsers = (publicGroupId, params, successHandler =() => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/public-group/delete/bulk/${publicGroupId}/users/`, params, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action addUserToPublicGroup = (pPublicGroupId, pUsers, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/public-group/${pPublicGroupId}/insert/users`, params, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action updatePublicGroup = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.patchRequestTemplate(`/api/public-group/${param.id}`, param, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action newPublicGroup = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/public-group/new`, param, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action addChild = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/public-group/${param.id}/addChild`, {title: param.title}, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action changeOrder = (changingOrderGroupId, baselineOrderGroupId, successHandler = () => {}, failHandler = () => {}) => {
    this.patchRequestTemplate(`/api/public-group/${changingOrderGroupId}/order-number`, baselineOrderGroupId, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action changeTreeOrder = (changingOrderGroupId, baselineOrderGroupId, successHandler = () => {}, failHandler = () => {}) => {
    this.patchRequestTemplate(`/api/public-group/tree/${changingOrderGroupId}/order-number`, baselineOrderGroupId, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action insertLastAtPid = (changingOrderGroupId, successHandler = () => {}, failHandler = () => {}) => {
    this.patchRequestTemplate(`/api/public-group/tree/${changingOrderGroupId}/last-pid/order-number`, null, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

}

export default PublicGroupManagerStore
