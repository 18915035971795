import {action, computed, makeObservable, observable} from 'mobx';
import NormalizedStore from 'src/store/NormalizedStore';
import React from 'react';
import {ColumnType} from "src/constants/AppConst";
import {empty} from "src/utils/objectUtils";

class CaseStore extends NormalizedStore {

    constructor() {
        super()
        makeObservable(this)
    }

    searchCategories = [
        {
            label: ['company_no'],
            field: 'company_no',
            type: ColumnType.STRING,
        },
        {
            label: ['case', 'name'],
            field: 'case_name',
            type: ColumnType.STRING,
            default: true
        },
        {
            label: ['createDt'],
            field: 'reg_dt',
            type: ColumnType.DATETIME,
        }
    ]

    columns = [
        {
            cellId: 'company_name',
            label: ['company', 'name'],
        },
        {
            cellId: 'case_name',
            label: ['case', 'name'],
            // useSort: true,
        },
        {
            cellId: 'voter',
            label: ['voter', 'count'],
        },
        {
            cellId: 'status',
            label: ['status'],
        },
        {
            cellId: 'reg_dt',
            label: ['createDt'],
            useSort: true,
        },
        {
            cellId: 'actions',
            label: ['detail'],
        },
    ]

    @observable company = {}
    @observable companies = []
    @observable isReadOnly = false
    @observable isDialogCaseEditForm = false
    @observable selectedCase = {}
    @observable isButton = true

    @action setIsButton = (param) => {
        this.isButton = param
    }

    @action setCompany = (param) => {
        this.company = param
    }

    @action setCompanies = (param) => {
        this.companies = param
    }

    @action setSelectedCase = (param) => {
        this.selectedCase = param
    }

    @action saveCurrentCompany = (id) => {
        this.setCompany(this.contents.find(content => id === content.id))
    }

    @action setIsReadOnly = (pURL) => {
        if(pURL === '/app/company/new') {
            this.isReadOnly = false
            this.setCompany({})
        } else if(pURL === '/app/company/edit/:id') {
            this.isReadOnly = false
        } else if(pURL === '/app/company/detail/:id') {
            this.isReadOnly = true
        } else {
            this.isReadOnly = true
        }
    }

    @computed get selectedCaseVal () {
        return this.selectedCase
    }


    @computed get caseContents () {
        if(empty(this.selectedCase)) {
            return null
        } else {
            return this.selectedCase.cases[0].contents
        }
    }

    @computed get companyEntityVal () {
        return [this.selectedCase.companyEntity]
    }

    @computed get caseAnswer () {
        if(empty(this.selectedCase)) {
            return null
        } else {
            return this.selectedCase.cases[0].answer
        }
    }

    @action setDialogCaseEditForm = (param) => {
        this.isDialogCaseEditForm = param
    }

    @action closeDialogCaseEditForm = () => {
        this.isDialogCaseEditForm = false
    }

    @action directSetContents = (param) => {
        this.contents = param
    }

    @action removeContents = (ids) => {
        this.directSetContents(this.contents.filter(content => !ids.find(selected => selected.id === content.id)))
    }

    @computed get selectedContentsIds () {
        return this.selectedContents.map(content => {
            return {id: content.id}
        })
    }

    @computed get formattedContents () {
        return this.contents.map(content => {
            content.isContentSelected = false
            content.editable = true
            return content
        })
    }



    // http Requests define
    @action getContent = (params = {} , successHandler = () => {}, failHandler = () => {})=> {
        this.getRequestTemplate(`/api/case/${params}`, {}, (res) => {
            this.selectedCase = res.data
            successHandler(res)
        }, (error) => {
            failHandler(error)
        })
    }

    @action getContents = (params = {} )=> {
        this.sortedRequestTemplate('/api/case', params, (res) => {
            this.initSelectedContents()
            this.setContents(res)
        })
    }

    @action deletes = (params = {}, successHandler = () => {}, failHandler = () => {}) => {
        this.postRequestTemplate('/api/company/deletes', params,
            (res) => {
                successHandler(res)
            },
            (error) => {
                failHandler(error)
            })
    }

    @action updateContents = (param ={}) => {
        this.contents = this.contents.map(content => {
            if(param.id === content.id ) {
                return param
            }
            return content
        })
    }

    @action save = (params = {}, successHandler = () => {}, failHandler = () => {}) => {
        this.postRequestTemplate('/api/case',
            params,
            (res) => successHandler(res),
            (error) => failHandler(error)
        )
    }
}
export default CaseStore
