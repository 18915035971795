/**
 * 해당 값이 배열에 존재여부 확인
 * @param key String key의 값을 갖는 배열 값
 */
Array.prototype.constratins = function(key) {
	for(var i=0; i<this.length; i++){
		if(this[i] == key){
			return true;
		}
	}
	return false;
};

/**
 * paramName의 Array parameter 생성
 * @param paramName
 * @returns {}
 */
Array.prototype.arrayToParams = function (paramName) {
    var ret = {};
    for(var i=0; i<this.length; i++){
        ret[paramName.concat('[').concat(i).concat(']')] = this[i];
    }
    return ret;
};
