import NormalizedStore from "src/store/NormalizedStore";
import {action, makeObservable, runInAction} from "mobx";
import {ColumnType} from "src/constants/AppConst";

class AdminStore extends NormalizedStore {

  constructor() {
    super('username');
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['admin', 'id'],
      field: 'username',
      type: ColumnType.STRING
    },
    {
      label: ['name'],
      field: 'name',
      type: ColumnType.STRING,
      default: true
    },
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      width: 70,
    },
    {
      cellId: 'username',
      label: ['name'],
      useSort: true,
    },
    {
      cellId: 'userDetail.celNo',
      label: ['celno'],
      width: 150,
    },
    {
      cellId: 'userDetail.states',
      label: ['address'],
      width: 320,
    }
  ]

  @action resetPasswords = (params = [], successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate('/api/admin/reset/user-password', params, successHandler, failHandler)
  }

  @action getAdmins = (params = {}, successHandler = () => {}) => {
    this.sortedRequestTemplate('/api/admin', params, (res) => {
      this.setContents(res)
    })
  }

  @action getCandidates = (params = {}) => {
    this.sortedRequestTemplate('/api/admin/candidate', params, (res) => {
      this.setContents(res)
    })
  }

  @action addAdmins = (params = {}, successHandler = () => {}) => {
    this.postRequestTemplate('/api/admin', params, (res) => {
      runInAction(() => {
        successHandler()
      })
    })
  }

  @action deleteAdmins = (params = {}, successHandler = () => {}) => {
    this.postRequestTemplate('/api/admin/delete', params, (res) => {
      runInAction(() => {
        successHandler()
      })
    })
  }
}

export default AdminStore
