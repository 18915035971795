/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
/**
 * 현재 날짜 추출
 */
Date.prototype.getToday = function (seperator) {
    try {
        let year = this.getFullYear().toString()
        let tempDate = this.getDate()
        let date = tempDate < 10 ? "0".concat(tempDate.toString()) : tempDate.toString()
        let tempMonth = this.getMonth() + 1
        let month = tempMonth < 10 ? "0".concat(tempMonth.toString()) : tempMonth.toString()
        let fullDate = year.concat(month).concat(date)
        if (seperator) {
            return fullDate.toDate(seperator)
        } else {
            return fullDate.toDate()
        }
    } catch (e) {
        // alert("Date.getToday : "  + e.description)
        console.log('Date.getToday error :', e)
        return ""
    }
}

/**
 * yyyyMMdd 형태의 날짜를 현재 객체에 설정
 */
Date.prototype.setCustomDate = function (date) {
    if (date.length !== 8) {
        return
    }

    this.setYear(date.substr(0, 4).to_i())
    this.setMonth(date.substr(4, 2).to_i() - 1)
    this.setDate(date.substr(6, 2).to_i())
}

/**
 * 인자의 날짜와 현재 날짜의 차이를 구함.
 */
Date.prototype.gapOfToday = function (date) {
    if (date.length !== 8) {
        return
    }

    let tempDate = new Date()
    tempDate.setCustomDate(date.trim())

    let dif = this - tempDate
    return parseInt(dif / 1000 / 60 / 60 / 24 * -1, 10)
}


Date.prototype.format = function (f) {

    if (!this.valueOf()) return " ";


    const weekKorName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

    const weekKorShortName = ["일", "월", "화", "수", "목", "금", "토"];

    const weekEngName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const weekEngShortName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const d = this;


    return f.replace(/(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi, function ($1) {

        switch ($1) {

            case "yyyy":
                return d.getFullYear(); // 년 (4자리)

            case "yy":
                return (d.getFullYear() % 1000).zf(2); // 년 (2자리)

            case "MM":
                return (d.getMonth() + 1).zf(2); // 월 (2자리)

            case "dd":
                return d.getDate().zf(2); // 일 (2자리)

            case "KS":
                return weekKorShortName[d.getDay()]; // 요일 (짧은 한글)

            case "KL":
                return weekKorName[d.getDay()]; // 요일 (긴 한글)

            case "ES":
                return weekEngShortName[d.getDay()]; // 요일 (짧은 영어)

            case "EL":
                return weekEngName[d.getDay()]; // 요일 (긴 영어)

            case "HH":
                return d.getHours().zf(2); // 시간 (24시간 기준, 2자리)

            case "hh":
                return ((d.getHours() % 12) ? d : 12).zf(2); // 시간 (12시간 기준, 2자리)

            case "mm":
                return d.getMinutes().zf(2); // 분 (2자리)

            case "ss":
                return d.getSeconds().zf(2); // 초 (2자리)

            case "a/p":
                return d.getHours() < 12 ? "오전" : "오후"; // 오전/오후 구분

            default:
                return $1;

        }

    });

};
