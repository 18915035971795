import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@material-ui/core";
import Draggable from "react-draggable";
import { labels } from "src/constants/ResourceBundle";
import { inject, observer } from "mobx-react";

const CommonDialog = ({ store }) => {

  const paperComponent = (props) => {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={"[class*=\"MuiDialogContent-root\"]"}>
        <Paper {...props} />
      </Draggable>
    );
  };

  const { dialogStore } = store;
  const { alertOpen, confirmOpen, title, content, closeHandler, okHandler, cancelHandler } = dialogStore;

  return (
    <Dialog
      open={confirmOpen || alertOpen}
      PaperComponent={paperComponent}
      aria-labelledby="draggable-dialog-title"
      onClose={closeHandler}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {confirmOpen &&
        <>
          <Button onClick={okHandler} color="primary">
            {labels("yes")}
          </Button>
          <Button autoFocus onClick={cancelHandler} color="primary">
            {labels("no")}
          </Button></>
        }

        {alertOpen && <Button onClick={okHandler} color="primary">
          {labels("confirm")}
        </Button>}

      </DialogActions>
    </Dialog>
  );
};

export default inject("store")(observer(CommonDialog));
