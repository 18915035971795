import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'
import _ from 'lodash'
import {DIRECTION_DESC} from "../../constants/AppConst";


class MailSearchStore extends NormalizedStore {
  constructor(root) {
    super('regDt')
    this.setDirection(DIRECTION_DESC)
    makeObservable(this)
    this.root = root;
    this.setDefaultBoxId()
  }

  @observable byteUnitSizes = [
    {
      value: 1024,
      label: 'MB',
    },
    {
      value: 1,
      label: 'KB',
    },
  ];

  @observable rangeTypes = [
    {
      value: true,
      label: '초과',
    },
    {
      value: false,
      label: '미만',
    },
  ];

  @observable sendUser = null
  @observable receiveUser = null
  @observable title = ''
  @observable containingWordInContent = ''
  @observable startDate = null
  @observable endDate = null
  @observable hasAttachFile = false
  @observable fileSize = 0
  @observable byteUnitSize = 1
  @observable overSize = true
  @observable _boxId = 'all'

  @observable _searchTrigger = false

  @action initSearchOptions = () => {
    this.sendUser = null
    this.receiveUser = null
    this.title = ''
    this.containingWordInContent = ''
    this.startDate = null
    this.endDate = null
    this.hasAttachFile = false
    this.fileSize = 0
    this.byteUnitSize = 1
    this.overSize = true
  }

  setSearchOptions = (params) => {
    this.sendUser = !_.isNil(params.sendUser) ? params.sendUser : null
    this.receiveUser = !_.isNil(params.receiveUser)? params.receiveUser : null
    this.title = !_.isNil(params.title) ? params.title : ''
    this.containingWordInContent = !_.isNil(params.containingWordInContent) ? params.containingWordInContent : ''
    this.startDate = !_.isNil(params.startDate) ? params.startDate : null
    this.endDate = !_.isNil(params.endDate) ? params.endDate : null
    this.hasAttachFile = !_.isNil(params.hasAttachFile) ? params.hasAttachFile : false
    this.fileSize = _.isNil(params.fileSize) ? 0 : parseInt(params.fileSize)
    this.byteUnitSize = !_.isNil(params.byteUnitSize) ? params.byteUnitSize : 1
    this.overSize = !_.isNil(params.overSize) ? params.overSize : true
  }

  getParams = () => {
    return {
      sendUserId : !_.isNil(this.sendUser) ? this.sendUser.id : null,
      receiveUserId : !_.isNil(this.receiveUser) ? this.receiveUser.id : null,
      title : this.title,
      containingWordInContent : this.containingWordInContent,
      startDate : this.startDate,
      endDate : this.endDate,
      hasAttachFile : this.hasAttachFile,
      fileSize : this.fileSize,
      byteUnitSize : this.byteUnitSize,
      overSize : this.overSize,
    }
  }

  @action searchMails = (box, params, successHandler = () => {}) => {
    runInAction(() => {
      if (!_.isNil(params)) {
        this.setSearchOptions(params)
      }
      this.setBoxId(box.id)

      const url = 'system_label' === box.type ?'/api/mail/' : '/api/mail/label/'
      this.sortedRequestTemplate(
        `${url}${box.id}`, this.getParams(), (res) => {
          successHandler(res)
          this.initSelectedContents()
          this.setContents(res)
          this.root.inboxStore.getUnreadInfo()
        })
    })
  }

  @computed get boxId() {
    return this._boxId
  }

  @action setBoxId = (boxId) => {
    this._boxId = boxId
  }

  @action setDefaultBoxId = () => {
    this._boxId = 'all'
  }

  @computed get searchTrigger() {
    return this._searchTrigger
  }

  @action pullSearchTrigger = () => {
    this._searchTrigger = !this._searchTrigger
    this.root.mailStore.getUsingInboxSize()
  }

  @action pullSearchTriggerAndInitPage = () => {
    this.setPage(0)
    this.pullSearchTrigger()
  }

  @action setSortAndDirection = (sort, direction) => {
    this.setSort({sort})
    this.setOrderBy(sort)
    this.setDirection(direction)
  }

  @computed get selectedMailIdsArray() {
    return this.selectedContents.map(content => content.id)
  }

  @computed get selectedReceivedMails() {
    // todo inboxStore의 typeToSend와 겹친다. refactoring
    return ['confirm', 'sent', 'drafts'].includes(this._boxId) ? [] : this.selectedContents.map(content => content.myInfo)
  }

  @computed get selectedReceivedMailIdsArray() {
    return ['confirm', 'sent', 'drafts'].includes(this._boxId) ? [] : this.selectedContents.map(content => content.myInfo.id)
  }

  @computed get isSelectedReceivedMails() {
    return this.selectedReceivedMailIdsArray.length > 0
  }

  @computed get isSelectedMails() {
    return this.selectedMailIdsArray.length > 0
  }

  @computed get allReceivedMails() {
    return this.contents.map(content => content.myInfo)
  }

  @computed get checkedMailsInfo() {
    let checkedStarCount = 0
    let notCheckedStarCount = 0
    let readCount = 0
    let unreadCount = 0

    if (!['confirm', 'sent', 'drafts'].includes(this._boxId)) {
      this.selectedContents.map(content => {
        'Y' === content.myInfo.starYn ? checkedStarCount++ : notCheckedStarCount++
        'Y' === content.myInfo.readDisplayYn ? readCount++ : unreadCount++
      })
    }

    return {
      hasCheckedStar: checkedStarCount > 0,
      hasUncheckedStar: notCheckedStarCount > 0,
      hasRead: readCount > 0,
      hasUnread: unreadCount > 0,
    };
  }

  @action initPageSize = (successHandler = () => {}) => {
    this.getRequestTemplate(
      '/api/users/pagesize',
      null,
      (res) => {
        this.setPageSize(res.data.mailPageSize)
        successHandler()
      }
    )
  }
}

export default MailSearchStore
