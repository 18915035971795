import {Grid} from '@material-ui/core'
import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {DEFAULT_MD, DEFAULT_SPACING, DEFAULT_XS} from 'src/constants/AppConst'

const GridContainer = forwardRef((
  {
    direction = 'column',
    children,
    spacing = DEFAULT_SPACING,
    md = DEFAULT_MD,
    xs = DEFAULT_XS,
    ...rest
  }, ref) => {
  return (
    <Grid
      container
      direction={direction}
      spacing={spacing}
      {...rest}
    >
      {children}
    </Grid>
  )
})

GridContainer.propTypes = {
  direction: PropTypes.string,
}

export default GridContainer
