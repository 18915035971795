import {action, computed, makeObservable, observable} from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'
import axios from "axios";
import SettingsStore from "../Settings/SettingsStore";
import {message} from "src/constants/ResourceBundle";


class FileStore extends NormalizedStore {

  constructor() {
    super('id')
    makeObservable(this)
    this.settings = new SettingsStore(this)
    this.settings.getSettings()
  }

  @observable files = [];

  @computed get getFiles () { return this.files }

  @action setFiles = (params, failHandler = () => {}) => {
    const deDupleAddFileList = params.filter(inputFile => {
      const duplicateFile = this.files.find(file => {
        return (inputFile.name === file.name) && (inputFile.lastModified === file.lastModified)
      })

      return !duplicateFile
    })

    if(!this.checkValidFileSize(deDupleAddFileList)){
      failHandler(message('ERR_OVER_FILE_UPLOAD_SIZE_LIMIT'));
      return -1
    }

    Array.prototype.push.apply(this.files, deDupleAddFileList)
    return 1
  }

  checkValidFileSize = (newFileList) => {
    let sumFilesSize = 0;
    let sumNewFilesSize = 0;

    const attachFileMaxSizeByte = this.settings.attachFileMaxSize * 1024 * 1024

     this.files.forEach(file => {
       sumFilesSize += file.size
     })

    newFileList.forEach(file => {
      sumNewFilesSize += file.size
    })

    return attachFileMaxSizeByte >= sumFilesSize + sumNewFilesSize
  }

  @action removeFiles = (filename) => {
    this.files = this.files.filter(file => filename !== file.name)
  }

  @action initializeFiles = () => this.files = []


  getFile = (fileId, mailId, successHandler) => {
    // axios.get(`/api/file/${fileId}`,{
    axios.get(`/api/file/download/mail/${mailId}/file/${fileId}`,{
      // todo progress bar
      // sample
      //  axios.get(url, {
      //             onDownloadProgress: (progressEvent) => {
      //                 let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //                 console.log(progressEvent.lengthComputable);
      //                 console.log(percentCompleted);
      //             }
      responseType: 'blob',
      // headers: { 'Accept': mimeType }
    }).then(res => {
      successHandler(res)
    })
  }

  downloadHandler = (res) => {
    const blob = new Blob([res.data], { type: res.headers['content-type'] })
    const fileName = decodeURI(this.getFileName(res.headers['content-disposition']))

    const link = document.createElement('a')
    link.setAttribute('download', fileName)
    link.href = window.URL.createObjectURL(blob)
    link.target='_self'

    link.click()
  }

  @action downloadFile = (userid, mailId) => {
    this.getFile(userid, mailId, this.downloadHandler)
  }

  setForwardFileHandler = (res) => {
    const fileName = decodeURI(this.getFileName(res.headers['content-disposition']))
    const file = new File([res.data], fileName)

    this.setFiles([file])
  }

  @action setForwardFiles = (mailId, params) => {
    params.forEach(param => {
      this.getFile(param.id, mailId, this.setForwardFileHandler)
    })
  }

  getFileName = (contentDisposition) => {
    let fileName = contentDisposition
      .split(';')
      .filter((ele) => {
        return ele.indexOf('filename') > -1
      })
      .map((ele) => {
        return ele
          .replace(/"/g, '')
          .split('=')[1].split("''")[1]
      })
    return fileName[0] ? fileName[0] : null
  }

  formatBytes =(bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

export default FileStore
