import {action, computed, makeObservable, observable, runInAction} from "mobx";
import NormalizedStore from "src/store/NormalizedStore";
import {ColumnType, DIRECTION_ASC} from "src/constants/AppConst";

class SignatureStore extends NormalizedStore {
  constructor() {
    super('regDt')
    makeObservable(this)
    this.signaturePtag = "<p id='signature'>"
    this.signaturePtagEnd = '</p>'
    this.direction = DIRECTION_ASC
  }

  searchCategories = [
    {
      label: ['title'],
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      label: ['message'],
      field: 'content',
      type: ColumnType.STRING,
      default: true
    },
    {
      label: ['createDt'],
      field: 'create_dt',
      type: ColumnType.DATETIME,
    }
  ]

  columns = [
    {
      cellId: 'title',
      label: ['title'],
      useSort: true,
    },
    {
      cellId: 'receive_user',
      label: ['receiver'],
    },
    {
      cellId: 'send_state',
      label: ['send', 'state'],
    },
    {
      cellId: 'create_dt',
      label: ['createDt'],
    },
    {
      cellId: 'actions',
      label: ['detail'],
    },
  ]

  @observable _signatures = []

  @observable isOpenSignatureEditDialog = false;

  @observable signatureSelected = {}

  @observable selectedIndex = -1

  @observable selectedContent = null

  @observable selected = {id: '', title: '', content: ''}

  @computed get getSelected(){ return this.selected}

  @action setSelected = (param) => {this.selected = param}

  @computed get getIsOpenSignatureEditDialog () { return this.isOpenSignatureEditDialog }

  @action openSignatureEditDialog = () => this.isOpenSignatureEditDialog = true

  @action closeSignatureEditDialog = () => this.isOpenSignatureEditDialog = false

  @computed get getSelectedContent () { return this.selectedContent }

  @action setSelectedContent = (param) => this.selectedContent = param

  @computed get getSelectedIndex() {return this.selectedIndex}

  @action setSelectedIndex = (param) => {
    this.selectedIndex = param
  }

  @computed get getSignatureSelected() {
    return this.signatureSelected
  }

  @computed get isSignatureSelected() {
    return !(_.isNil(this.signatureSelected.id))
  }

  @computed get signatures() {
    return this._signatures
  }

  findSignatureById = (pId) => {
    return this._signatures.find(signature => signature.id === pId)
  }

  @action setSignatures = (param) => {
    this._signatures = param
  }

  validateName = (pName) => {
    if(_.isNil(pName)){return false}
    return !(this._signatures.findIndex(item => pName === item.title) > 0)
  }

  @action selectSignature = (param) => this.signatureSelected = param

  @action removeSignatureSelected = () => this.signatureSelected = {}

  @computed get getUsingSignature() {
    if(_.isNil(this._signatures)) return null
    return this._signatures.find(signature => 'Y' === signature.main)?.content
  }

  @action getSignatures = () => {
    this.sortedRequestTemplate(`/api/signature`, null, (res) => {
      runInAction(() => {
        this._signatures = res.data
      })
    })
  }

  @action updateMainSignature = (param, successHandler = () => {}) => {
    this.patchRequestTemplate(`/api/signature/${param}/main`, null, (res) => {
      successHandler(res)
      this._signatures.forEach(sign => {
        sign.main = param === sign.id ? 'Y' : 'N'
      })
    })
  }

  @action save = (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate('/api/signature', param, (res) => {
      runInAction(() => {
        if ('Y' === param.main) {
          this._signatures.forEach(sign => {
            sign.main = param.id === sign.id ? 'Y' : 'N'
          })
        }
        this._signatures = [...this._signatures, res.data]
        successHandler(res)
      })
    }, (error) => {
      errorHandler(error.response.data);
    })
  }

  @action update = (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate(`/api/signature/${param.id}`, param, (res) => {
      runInAction(() => {
        successHandler && successHandler(res)
      })
    }, (error) => {
      errorHandler && errorHandler(error);
    })
  }

  @action deleteSignature = (param, index, successHandler = () => {}) => {
    this.deleteRequestTemplate(`api/signature/delete/${param}`, (res) => {
      this.deleteSignatureByIndex(index)
      successHandler(res)
    })
  }

  @action deleteSignatureByIndex = (index) => {
    this._signatures.splice(index, 1)

    if (index === this.getSelectedIndex) {
      this.setSelectedIndex(-1)
      this.setSelected({})
    }
    if (index < this.getSelectedIndex) {
      this.setSelectedIndex(this.getSelectedIndex-1)
    }
  }
}

export default SignatureStore
