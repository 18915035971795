import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const MenuRoute = [
  {
    path: '/app/menu',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/menu/',
        component: lazy(() => import('src/views/menu/MenuListView'))
      },

    ]
  }
]

export default MenuRoute
