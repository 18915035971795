export const PAGE_SIZE = 10
export const FIRST_PAGE = 0
export const DIRECTION_ASC = 'ASC'
export const DIRECTION_DESC = 'DESC'
export const DEFAULT_SPACING = 1
export const DEFAULT_MD = 12
export const DEFAULT_XS = 12
export const DEFAULT_XL = 12
export const REGEXP_EMAIL = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/
export const REGEXP_PASS = /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/
export const DATE_TIME_SEARCH_FORMAT='YYYYMMDDHHmmss'
export const TEL_NO = /^.*(?=^.{10,}$)(?=.*\d).*$/
export const AUTO_HIDE_DURATION=3000
export const RESULT_SUCCESS = 'success'
export const RESULT_ERROR = 'error'
export const BUSINESS_NUMBER = /^[0-9]{10}/
export const CORPORATION_NUMBER = /^[0-9]{13}/
export const MAIN_DOMAIN = process.env.MAIN_DOMAIN || 'evote.com'

export const ResultType = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
})

export const ColumnType = Object.freeze({
  DATETIME: 'datetime',
  STRING: 'string',
  NUMBER: 'number'
})
