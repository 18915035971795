import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const UserManagementRoute = [
  {
    path: '/app/management/user',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        path: '/app/management/user',
        component: lazy(() => import('src/views/userManagement/UserListView'))
      },
      /*{
        exact: true,
        path: '/app/management/user/edit',
        component: lazy(() => import('src/views/user/UserEditView'))
      },*/
      {
        exact: true,
        path: '/app/management/user/new',
        component: lazy(() => import('src/views/userManagement/UserEditView'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/app/management/user/edit/:username',
        component: lazy(() => import('src/views/userManagement/UserEditView'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/app/management/user/detail/:username',
        component: lazy(() => import('src/views/userManagement/UserDetailView'))
      },
    ]
  }
]

export default UserManagementRoute
