import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import NormalizedStore from 'src/store/NormalizedStore';
import { DIRECTION_ASC } from 'src/constants/AppConst';

class TreeStore extends NormalizedStore {

  constructor() {
    super();
    makeObservable(this);
    this._orgBatchUrl = '/api/orgbatch'
    this._topCode = '10000000000'
  }

  @observable _key = 0

  @action getOrder = () => {
    this._key = ++ this._key
    return this._key
  }

  setChild = (pcode, fullData) => {
    let childList = fullData.filter(elem => pcode === elem.orbPcode)
    let current = {}
    const order = this.getOrder()
    if(this._topCode === pcode) {
      current = {
        orbName : '최상위',
        orgBatchPKs : {orbCode : pcode}
      }
    } else {
      current = fullData.find(elem => pcode === elem.orgBatchPKs.orbCode)
    }
    if(undefined === childList || null === childList || 0 === childList.length) {
      return {
        orbPcode: pcode,
        key: order,
        current:current,
        children: null
      }
    } else {
      return {
        orbPcode: pcode,
        current:current,
        order:order,
        children: childList.map(child => {
          return this.setChild(child.orgBatchPKs.orbCode, fullData)
        })}
    }
  }

  @computed get treeFormatContents() {
    let result = []
    if (0 === this.contents.length)
      return this.contents
    result = this.setChild(this._topCode, this.contents)
    console.debug('result : ', result);
    return result
  }

  // overide
  setContents = (response) => {
    runInAction(() => {
      const data = response.data
      this.contents = data
    })
  }

  @action getTree = (params = {}, successHandler = () => {}, failHandler = () => {}) => {
    const sortedParams = {
      sortings: [{
        column: 'orbOrder',
        direction: DIRECTION_ASC.toUpperCase(),
      }, {
        column: 'orbName',
        direction: DIRECTION_ASC.toUpperCase(),
      }]
    }

    this.getRequestTemplate(`${this._orgBatchUrl}/tree/${params}`, sortedParams, (res) => {
      this.setContents(res)
      successHandler()
    }, failHandler())
  }
}

export default TreeStore;
