import {action, computed, makeObservable, observable, runInAction} from "mobx";
import NormalizedStore from 'src/store/NormalizedStore'
import {ColumnType} from "src/constants/AppConst";

class ContactStore extends NormalizedStore {
    constructor(root) {
        super('id')
        makeObservable(this)
        this.root = root
    }

    searchCategories = [
        {
            label: ['name'],
            field: 'username',
            type: ColumnType.STRING,
        },
        {
            label: ['id'],
            field: 'id',
            type: ColumnType.STRING,
        },
    ]

    columns = [
        {
            cellId: 'id',
            label: ['id'],
            width: 70,
        },
        {
            cellId: 'username',
            label: ['name'],
            width: 50,
            useSort: true,
        },
        {
            cellId: 'mail',
            label: ['mail'],
        },

    ]

    @observable username = ''
    @observable isOpen = false
    @observable contactList = []
    @observable tagList = []
    @observable updateList = []
    @observable addTagDialogIsOpen = false
    @observable updateTagDialogIsOpen = false

    copyList = (baseList, type) => {
        return baseList.map(item => {
            const copied = item
            copied['refType'] = type
            return copied
        }) || []
    }

    @computed get getContactList() {
        return this.contactList
    }

    @computed get isEmptySelectedContactList() {
        return 0 === this.contactList.length
    }


    @action setUsername = (pName) => {
        this.username = pName
    }

    @computed get getAddTagDialogIsOpen(){
        return this.addTagDialogIsOpen
    }

    @action closeAddTagDialog = () => {
        this.addTagDialogIsOpen = false
    }

    @action openAddTagDialog = () => {
        this.addTagDialogIsOpen = true
    }

    @action openUpdateTagDialog = () => {
        this.updateTagDialogIsOpen = true
    }

    @action closeUpdateTagDialog = () => {
        this.updateTagDialogIsOpen = false
    }


    @action closeOpen = () => {
        runInAction(() => {
            this.updateTagDialogIsOpen = false
            this.addTagDialogIsOpen = false
        })
    }

    @action openContactDialog = () => {
        this.initContactListFromMailForm()
        this.isOpen = true
    }

    /*// todo 상속에서 직접 구현
    @action initContactListFromMailForm = () => {
        // have to override
    }

    // todo 상속에서 직접 구현
    @action addMailForm = () => {
        // have to override
    }

    // todo 상속에서 직접 구현
    getValidContact = (type) => {
        // have to override
    }*/

    @action addReceiverList = (param) => {
        const filteredContents = this.selectedContents.filter(selected => {
            return this.contactList.findIndex(receiver => receiver.id === selected.id) === -1
        })

        filteredContents.forEach(selected => {selected['refType'] = param})
        this.contactList = [...this.contactList, ...filteredContents]
        this.initSelectedContents()
    }

    @action closeContactDialog = () => this.isOpen = false

    @action setContactList = (param = []) => {
        this.contactList = param
    }

    @action removeReceiverHandler = (id) => {
        this.contactList = this.contactList.filter(item => {
            return item.id !== id
        })
    }



    @action clearContactList = () => {
        this.contactList = []
    }

    @action hasTag = (tagId, selectedContents) => {
        if(selectedContents.length === 0|| _.isNil(this.tagList)) { return false}
        const userIds = selectedContents.map(user => user.id)
        const tag = this.tagList.find((item => item.id === tagId))

        const existsUser = _.isNil(tag.users) ? 0 : tag.users.filter(user => {
            return !_.isNil(userIds.find(userId => userId === user.id))
        })

        return existsUser.length === userIds.length
    }

    @action isUpdate = (tagId) => {
        if(this.selectedContents.length === 0){return false}
        return -1 === this.selectedContents.findIndex(user => false === this.checkUpdate(tagId, user))
    }

    @action checkUpdate = (tagId, user) => {
        const tag = this.updateList.find(update => update.id === tagId)
        if(!tag){
            return false
        }

        return -1 !== tag.users.findIndex(userItem => userItem.id === user.id)
    }

    @action setUserToUpdateTags= (tagid, users) => {
        users.forEach(user => this.setUserToUpdateTag(tagid, user))
    }

    @computed get getUpdateList() {
        return this.updateList.filter(update => {
            return update.users.length > 0
        })
    }

    @action initUpdateList = () => {
        this.updateList = []
    }

    @action setUserToUpdateTag = (pTagid, user) => {
        const tagId = pTagid
        let tag = this.updateList.find(update => update.id === tagId)
        if(!tag){
            tag = {'id' : tagId, 'userTagEntityList': [], 'users': [user]}
            this.updateList.push(tag)
        } else {
            if(-1 !== tag.users.findIndex(userItem => userItem.id === user.id)){
                tag.users = tag.users.filter(userItem => userItem.id !== user.id)
            } else {
                tag.users.push(user)
            }

            this.updateList = this.updateList.map(update => {
                if(update.id === tag.id){
                    return tag
                }
                return update
            })
        }

        return true
    }

    @computed get getIsOpenContactDialog() {
        return this.isOpen
    }

    @computed get getTagList() {
        return this.tagList
    }

    @action getContents = (params = {}, finalHandler = () => {}) => {
        this.sortedRequestTemplate('/api/users', params, (res) => {
            this.setContents(res)
        }, null, () => {
            finalHandler()
        })
    }

    @action getUsersByTag = (tagId, param ={}, successHandler = () => {}) => {
        this.sortedRequestTemplate(`/api/tag/${tagId}/users`, param, (res) => {
            runInAction(() => {
                successHandler(res)
                this.setContents(res)
            })
        })
    }

    @action getUserTagsByUser = (pUsername = '') => {
        let username = pUsername || this.username
        this.getRequestTemplate(`/api/tag/user/${username}`, null, (res) => {
            this.tagList = res.data
        })
    }

    @action saveUsersAtTag = (successHandler) => {

        const newArray = []
        this.updateList.forEach(x => {
            return x.users.forEach(y => {
                newArray.push({tag:{id : x.id}, user:{id: y.id}})
            })
        })

        this.postRequestTemplate(`/api/tag/`, newArray, () => {
            successHandler()
        })
    }

    @action saveTag = (param, successHandler) => {
        this.postRequestTemplate(`/api/tag/new`, param, () => {
            successHandler()
        })
    }

    getUserName = (param) => {
        return `${param.userDetail.lastName} ${param.userDetail.firstName}`
    }

}


export default ContactStore
