import { lazy } from "react";
import AuthGuard from "src/components/AuthGuard";
import DashboardLayout from "src/layouts/DashboardLayout";

const MessageRoute = [
  {
    path: "/app/message",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/message",
        component: lazy(() => import("src/views/message/MessageListView"))
      }
    ]
  }
];

export default MessageRoute;
