import User from './User'
import Common from './Common'
import Sample from './Sample'
import AddressGroup from './AddressGroup'
import Message from './Message'
import Dialog from './Dialog'
import Menu from './Menu'
import Company from './Company'
import Auth from './Auth'
import Case from './Case'
import Vote from './Vote'
import Mail from './Mail'
import PublicAddressBook from "./PublicAddressBook";
import Tree from './Tree'
import BreadCrumb from "./BreadCrumbStore";
import Admin from "./Admin";
import File from './File'
import Signature from './Signature'
import Editor from "./Editor";
import Settings from "./Settings"
import Organization from "./Organization";

export default function storeInitializer () {
  return {
    ...AddressGroup,
    ...User,
    ...Common,
    ...Sample,
    ...Message,
    ...Dialog,
    ...Menu,
    ...Company,
    ...Auth,
    ...Case,
    ...Vote,
    ...new Mail(),
    ...PublicAddressBook,
    ...BreadCrumb,
    ...Tree,
    ...BreadCrumb,
    ...Admin,
    ...File,
    ...Signature,
    ...Editor,
    ...Settings,
    ...Organization,
  }
}
