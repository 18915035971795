import { label } from 'src/constants/ResourceBundle';

export const filterTypes = Object.freeze({
  MAIL_TITLE: {code: 'mail_title', name: label(['mail_title'])},
  MAIL_CONTENT:{code: 'mail_content', name: label(['mail_title'])},
});


export const receiverType = Object.freeze({
  RECEIVER:'goal',
  REFERENCE:'ref',
  HIDDEN: 'hidden'
});