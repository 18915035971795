import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios'
import { empty } from 'src/utils/objectUtils';

class CodeStore {

  constructor() {
    makeObservable(this)
  }

  @observable codes
  @observable codesArrays = {}

  getCodeValueById(arg = 0) {
    const searchInt = isNaN(parseInt(arg)) ? parseInt(arg) : 0
    let result = '';
    if(!empty(this.codes)) {
      const code = this.codes.find(code => code.id === searchInt)
      result = code ? code.codeValue : ''
    }
    return result
  }

  @computed get computedCodesArrays () { return this.codesArrays}

  @action getCodeList(codeGroup) {
    axios.get(`/api/common/code/${codeGroup}`)
      .then(res => {
        this.codes = res.data;
        this.codesArrays[`${codeGroup}`] = res.data
      })
  }

  getAsyncCodeList = async () => {
    const res = await axios.get(`/api/common/code/${codeGroup}`)
    return res.data
  }
}

export default CodeStore


