import {action, computed, makeObservable, observable} from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'

class SettingsStore extends NormalizedStore {
  constructor() {
    super()
    makeObservable(this)
    this.getSettings()
  }

  @observable settings = {};

  @computed get getComputedSettings() {
    return this.settings
  }

  @computed get settingsId() {
    return this.settings.id ?? 0
  }

  @computed get attachFileStorePeriod() {
    return this.settings.attachFileStorePeriod ?? 0
  }

  @computed get attachFileMaxSize() {
    return this.settings.attachFileMaxSize ?? 0
  }

  @computed get mailContentMaxSize() {
    return this.settings.mailContentMaxSize ?? 0
  }

  @computed get inboxMaxSize() {
    return this.settings.inboxMaxSize ?? 0
  }

  @action getSettings = () => {
    this.getRequestTemplate(
      '/api/settings',
      null,
      (res) => {
        this.settings = res.data
      }
    )
  }

  @action setSettings = (param = {}, successHandler = () => {}, errorHandler = () => {}) => {
    this.patchRequestTemplate(`/api/settings`, param, (res) => {
      this.settings = res.data
      successHandler && successHandler(res.data)
    }, (error) =>{
      errorHandler && errorHandler(error)
    })
  }
}

export default SettingsStore
