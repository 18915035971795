import React from 'react'
import clsx from 'clsx'
import {Box, Button, makeStyles, TextField} from '@material-ui/core'
import {desc, labels, message} from "src/constants/ResourceBundle"
import {useForm} from "react-hook-form";
import GridContainer from "src/components/GridWrapper/GridContainer";
import GridRow from "src/components/GridWrapper/GridRow";
import GridColumn from "src/components/GridWrapper/GridColumn";
import {inject, observer} from "mobx-react";
import {RESULT_ERROR} from "src/constants/AppConst";

const useStyles = makeStyles(() => ({
  root: {}
}));

const JWTLogin = ({store, className, ...rest}) => {

  const classes = useStyles();
  const {snackbarStore, authStore, settingsStore} = store
  const {showSnackbar} = snackbarStore
  const {register, handleSubmit, errors} = useForm({
    mode: 'onChange',
  })

  const submitHandler = async data => {
    const { username, password } = data
    await authStore.login(
      username,
      password,
      () => {
        settingsStore.getSettings()
        location.replace('/app/management/mail/all')
      },
      () => {
        showSnackbar({ message: message('Unauthorized'), state: RESULT_ERROR })
      }
    )
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(submitHandler)}
      className={clsx(classes.root, className)}
      {...rest}
      onInvalid={(e) => e.preventDefault()}
    >
      <GridContainer>
        <GridRow>
          <GridColumn>
            <TextField
              autoFocus={true}
              inputRef={register({
                required: {value: true, message: desc('required', 'userid') || '아이디를 입력해주세요.'},
                pattern: {value: /^[A-Za-z0-9]{4,20}$/, message: desc('userid', 4, 20)},
              })}
              component={'div'}
              error={Boolean(errors.username)}
              fullWidth
              helperText={errors.username?.message}
              label={labels('userid') || '아이디'}
              name='username'
              // defaultValue={user.username}
              variant='outlined'
              required
              // inputProps={{readOnly:isEdit}}
            />
          </GridColumn>
          <GridColumn>
            <TextField
              inputRef={register({
                required: {value: true, message: desc('required', 'password') || '비밀번호를 입력해주세요.'},
                // pattern: {value: REGEXP_PASS, message: desc('passwd', 8, 20)},
              })}
              error={Boolean(errors.password)}
              fullWidth
              helperText={errors.password?.message}
              label={labels('pass')  || '비밀번호'}
              name='password'
              variant='outlined'
              type='password'
              required
              // onChange={checkPassword}
            />
          </GridColumn>
        </GridRow>
      </GridContainer>
      <Box mt={2}>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          fullWidth
          size="large"
        >
          {labels('login') || '로그인'}
        </Button>
      </Box>
    </form>
  );
};

export default inject('store')(observer(JWTLogin));
