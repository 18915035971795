import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'
import { ColumnType } from 'src/constants/AppConst'
import { colors } from '@material-ui/core'
import { labels } from 'src/constants/ResourceBundle'
import _ from 'lodash'
import MailIcon from "@material-ui/icons/Mail";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import StarIcon from "@material-ui/icons/Star";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import {AddBox} from "@material-ui/icons";
import SettingsIcon from '@material-ui/icons/Settings';

class InboxStore extends NormalizedStore {

  constructor(root) {
    super('id')
    makeObservable(this)
    this.root = root
  }

  searchCategories = [
    {
      label: ['title'],
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      label: ['createDt'],
      field: 'create_dt',
      type: ColumnType.DATETIME,
      default: true
    }
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      useSort: true,
    },
    {
      cellId: 'regDt',
      label: ['createDt'],
    },
    {
      cellId: 'actions',
      label: ['detail'],
    },
  ]

  @observable _boxes = [
    {
      id: 'all',
      type: 'system_label',
      name: ['received','letterbox'],
      icon: MailIcon,
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'me',
      type: 'system_label',
      name: ['to_me','written', 'letterbox'],
      icon: PersonIcon,
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'sent',
      type: 'system_label',
      name: ['sent', 'letterbox'],
      icon: SendIcon,
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'confirm',
      type: 'system_label',
      name: ['receive', 'confirm'],
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'drafts',
      type: 'system_label',
      name: ['draft', 'keepingbox'],
      icon: DraftsIcon,
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'trash',
      type: 'system_label',
      name: ['trashcan'],
      icon: DeleteIcon,
      unreadCount: 0,
      totalCount: 0
    },
    /*{
        id: 'spam',
        type: 'system_label',
        name: ['spam'],
        icon: ReportIcon,
        iconCode: ['spam'],
        unreadCount: 1,
        totalCount: 1
    },*/
    {
      id: 'important',
      type: 'system_label',
      name: ['important', "letterbox"],
      icon: LabelImportantIcon,
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'starred',
      type: 'system_label',
      name: ['starred', 'letterbox'],
      icon: StarIcon,
      iconCode: ['starred', 'letterbox'],
      unreadCount: 0,
      totalCount: 0
    },
  ];

  _newBox = {
    id: 'newBox',
    type: 'system_label',
    name: ['new', 'label'],
    icon: AddBox,
    iconCode: 'newBox',
    unreadCount: 0,
    totalCount: 1,
    color: colors.grey[600]
  }

  _settingBox = {
    id: 'settingBox',
    type: 'system_label',
    name: ['settings'],
    icon: SettingsIcon,
    iconCode: 'settings',
    color: colors.grey[600]
  }

  _emptyFilterDetail = {
    filterTypeCd : null,
    filterContent : null,
  }

  @observable _isSidebarOpen = false
  @observable _isOpenReceiverSearch = false
  @observable _searchName = null
  @observable _isOpenNewLabelDialog = false
  @observable _isSavedTrigger = false
  @observable _editLabel = {}
  @observable _filterDetails = [this._emptyFilterDetail]
  @observable _box = this._boxes.find(box => 'all' === box.id)
  @observable _isSettingsOpen = false

  @action setCurrentBox = (box) => {
    this._box = box
  }

  @action setCurrentBoxToDefault = () => {
    this._box = this._boxes.find(box => 'all' === box.id)
  }

  @computed get currentBox() {
    return this._box
  }

  @computed get confirmBox() {
    return this._boxes.find(box => 'confirm' === box.id)
  }

  @computed get newBox() {
    return this._newBox
  }

  @computed get settingBox() {
    return this._settingBox
  }

  @computed get typeToSend() {
    return ['sent', 'drafts'].includes(this._box.id)
  }

  @action closeNewLabelDialog = () => {
    this._editLabel = {}
    this._isOpenNewLabelDialog = false
  }

  @action addEmptyFilter = () => {
    const emptyObject = _.cloneDeep(this._emptyFilterDetail);
    this._filterDetails.push(emptyObject)
  }

  @computed get filterDetails () { return this._filterDetails }

  @action openNewLabelDialog = () => { this._isOpenNewLabelDialog = true }

  @action openEditLabelDialog = (param) => {
    this._isOpenNewLabelDialog = true
    this.setEditLabel(param)
  }

  getLabelById = (id) => {
    return this.contents
      .find(content => content.id === id)
  }

  @action setEditLabel = (param) => {
    console.debug('setEditLabel', param)
    this._editLabel = param
  }

  @computed get editLabel () {
    return this._editLabel
  }

  @computed get hasEditLabel () {
    return !(undefined === this.editLabel.id || null === this.editLabel.id || {} === this.editLabel.id)
  }

  @computed get boxes () {
    return [...this._boxes, ...this.customBoxes]
  }

  @computed get letterBoxes () {
    return [...this._boxes.filter(box => 'confirm' !== box.id), ...this.customBoxes]
  }

  @computed get customBoxes () {
    return this.contents.map(content => {
      return {
        id: content.id,
        type: 'custom_label',
        name: content.labelName,
        unreadCount: content.unreadCount,
      }
    })
  }

  @computed get isSettingsOpen () {
    return this._isSettingsOpen
  }

  @action setSettingsOpen = (val) => {
    this._isSettingsOpen = val
  }

  @action setNewBox(param) {
    this._boxes.add(param)
  }

  @action triggerSavedLabel = () => {
    this._isSavedTrigger = !this._isSavedTrigger
    console.debug('triggerSavedLabel is', this._isSavedTrigger)
  }

  @computed get isSavedTrigger () {
    return this._isSavedTrigger
  }

  @computed get isOpenNewLabelDialog () { return this._isOpenNewLabelDialog }

  @action setSearchName = (name) => { this._searchName = name }

  @computed get searchName () { return this._searchName }

  @action setIsSidebarOpen = (val) => {
    this._isSidebarOpen = val
  }

  @action sideBarOff = () => {
    this.setIsSidebarOpen(false)
  }

  @action sideBarOn = () => {
    this.setIsSidebarOpen(true)
  }

  @computed get isSidebarOpen() {
    return this._isSidebarOpen;
  }

  @action getBoxById = (id) => this.boxes.find(box => id === box.id)

  getName = (box) => {
    if('system_label' === box.type){ return labels(...box.name) }
    return box.name
  }

  @action saveLabel = (param, successHandler = () => {}) => {
    this.postRequestTemplate('/api/label/', param, (res) => {
      successHandler(res)
    })
  }

  @action renameLabel = (params, successHandler = () => {}) => {
    this.patchRequestTemplate(`/api/label/${params.id}`, params, (res) => {
      successHandler(res)
    })
  }

  @action deleteLabel = (params, successHandler = () => {}) => {
    this.deleteRequestTemplate(`/api/label/${params.id}`, (res) => {
      successHandler(res)
    })
  }

  @action addAMailToLabel = (id, mailId, successHandler = () => {}) => {
    this.postRequestTemplate(`/api/label/${id}/mail/${mailId}`, null, (res) => {
      runInAction(() => {
        successHandler(res)
      })
    })
  }

  @action addMailsToLabel = (id, receivers, successHandler = () => {}) => {
    this.postRequestTemplate(`/api/label/${id}/mail`, receivers, (res) => {
      runInAction(() => {
        successHandler(res)
      })
    })
  }

  @action removeMail = (params, successHandler = () => {}) => {
    this.deleteRequestTemplate(`/api/label/${params.id}/mail/remove/${params.mailId}`, (res) => {
      successHandler(res)
    })
  }

  @action removeMails = (labelId, params, successHandler = () => {}) => {
    this.postRequestTemplate(`/api/label/${labelId}/mail/remove`, params,(res) => {
      successHandler(res)
    })
  }

  @action getLabels = (param) => {
    this.sortedRequestTemplate('/api/label', param, (res) => {
      this.setContents(res)
    })
  }

  @action getUnreadInfo = () => {
    this.getRequestTemplate('/api/mail/unread-info', null, (res) => {
      runInAction(() => {
        this._boxes.forEach(box => {
          box.unreadCount = (res.data[box.id]) ? res.data[box.id] : 0
        })
        this.contents.forEach(box => {
          box.unreadCount = (res.data[box.id]) ? res.data[box.id] : 0
        })
      })
    })
  }

  @computed get isReceivedBox() {
    return ['all', 'me', 'important', 'starred'].includes(this._box.id)
  }

  @computed get isTrashBox() {
    return 'trash' === this._box.id
  }

  @computed get isDeletePermanently() {
    return this.isTrashBox || 'drafts' === this._box.id
  }

  @computed get isCustomBox() {
    return 'custom_label' === this._box.type
  }

  @computed get isSentBox() {
    return ['sent', 'confirm'].includes(this._box.id)
  }

  @computed get mailListOptions() {
    return {
      useMoveToLabel: this.isReceivedBox || this.isCustomBox,
      useRemoveInLabel: this.isCustomBox,
      useRead: this.isReceivedBox || this.isCustomBox,
      useDelete: this.isReceivedBox,
      useDeletePermanently: this.isDeletePermanently,
      useRestore: this.isTrashBox,
      useResend: this.isSentBox,
    }
  }
  @computed get mailDetailOptions() {
    return {
      useReply: this.isReceivedBox || this.isCustomBox,
      useMoveToLabel: this.isReceivedBox || this.isCustomBox,
      useRead: this.isReceivedBox || this.isCustomBox,
      useDelete: this.isReceivedBox,
      useDeletePermanently: this.isDeletePermanently,
      useRestore: this.isTrashBox,
      useResend: this.isSentBox,
    }
  }
}

export default InboxStore
