import ContactStore from "./ContactStore";
import {action, makeObservable} from "mobx";
import {receiverType} from "src/views/mail/MailView/enums";

class PublicGroupManagerContactStore extends ContactStore {
    constructor(root) {
        super('id')
        makeObservable(this)
        this.root = root
    }

    // Override
    // 초기값 세팅
    @action initContactListFromMailForm = () => {
        // nothing
    }

    // Override
    // 추가 버튼 클릭
    @action addMailForm = (successHandler, failHandler) => {
        console.debug('addMailForm')
        this.saveUsers(this.root.publicGroupManagerStore.selectedPublicGroup.id, this.getValidContact(receiverType.RECEIVER), () => {
            this.clearContactList()
            successHandler()
        }, (error) => {
            failHandler(error)
        })
    }

    // Override
    getValidContact = (type) => {
        return this.contactList.filter(contact => type === contact.refType) || []
    }

    @action saveUsers = (pTagId, pUsers, successHandler, failHandler) => {
        const newArray = []

        pUsers.forEach(x => {
            newArray.push({publicGroupEntity:{id : pTagId}, user:{id: x.id}})
        })

        this.postRequestTemplate(`/api/public-group/`, newArray, () => {
            successHandler()
        }, (res) => failHandler(res))
    }

}

export default PublicGroupManagerContactStore