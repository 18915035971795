import {action, makeObservable, observable, computed} from "mobx";
import axios from "axios";
import NormalizedStore from "src/store/NormalizedStore";
import {paramParser} from "src/utils/paramParser";
import {message} from 'src/constants/ResourceBundle';
import {ColumnType, RESULT_ERROR, RESULT_SUCCESS} from 'src/constants/AppConst';
import _ from 'lodash';

class UserStore extends NormalizedStore {

  constructor() {
    super('id')
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['userid'],
      field: 'id',
      type: ColumnType.STRING
    },
    {
      label: ['name'],
      field: 'username',
      type: ColumnType.STRING,
      default: true
    },
    {
      label: ['regDate'],
      field: 'regDt',
      type: ColumnType.DATETIME
    },
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      width: 70,
    },
    {
      cellId: 'username',
      label: ['name'],
      useSort: true,
    },
    {
      cellId: 'userDetail.celNo',
      label: ['celno'],
      width: 150,

    },
    {
      cellId: 'userDetail.states',
      label: ['address'],
      width: 320,
    },
    {
      cellId: 'regDt',
      label: ['regDate'],
      useSort: true,
      type: 'dateTime',
      width: 200,
    },
    {
      cellId: 'edit',
      label: ['edit'],
    },
    {
      cellId: 'description',
      label: ['description'],
      isSearch: false, align: "right",
    }
  ]

  pageSizeOptions = [10, 20, 30, 40, 50, 100]

  getEmptyToNull = (param) => {
    return _.isNil(param) ? '' : param
  }

  getName = (userObj) => {
    const firstName = userObj.userDetail.firstName
    const lastName = userObj.userDetail.lastName

    return `${lastName} ${firstName}`
  }

  getAddress = (userObj) => {
    const userDetail = userObj.userDetail
    const state = this.getEmptyToNull(userDetail.state)
    const streetDong = this.getEmptyToNull(userDetail.streetDong)
    const address = this.getEmptyToNull(userDetail.address)
    const zipcode = this.getEmptyToNull(userDetail.zipcode)
    return _.trim(`${state} ${streetDong} ${address} ${zipcode}`, ' ')
  }

  @observable user = {userDetail: {}}
  @observable isEditOpen = false
  @observable me = {userDetail: {}}
  @observable isChangePasswordOpen = false

  @action setEditOpen = (isEditOpen) => {
    this.isEditOpen = isEditOpen
  }

  @action setChangePasswordOpen= (isChangePasswordOpen) => {
    this.isChangePasswordOpen = isChangePasswordOpen
  }

  @action setUser = (user) => this.user = user

  @action setMe = async (username) => this.me = await this.getUser(username)

  @computed get getMe () {
    return this.me
  }

  @action getUser = async (username = '', params = {}) => {
    const res = await axios.get(`/api/users/${username}`, {params})
    return res.data
  }

  @action deleteUsersByUserId = async (users, successHandler, failHandler) => {
    this.setLoading(true)
    axios.post('/api/users/deletes', users)
      .then(() => {
        successHandler()
      })
      .catch(() => {
        failHandler()
      })
      .finally(() => this.setLoading(false))
  }

  @action getUsers = (params = {}) => {
    this.sortedRequestTemplate('/api/users', params, (res) => {
      this.setContents(res)
    })
  }

  @action saveUser = async (params = {}, successHandler = () => {}) => {
    let result = {message: message('MSG_SAVE_SUCCESS'), status: RESULT_SUCCESS, result: true}
    await axios.post('/api/users', params)
      .then(() => {
        successHandler()
      })
      .catch((error) => {
        const {message} = error['response']['data']
        result = {status: RESULT_ERROR, message, result: false}
      })
    return result
  }

  @action getUsersWithAddressGroup = (params = {}, successHandler = () => {}) => {
    this.setLoading(true)
    axios.get(`/api/users?${paramParser(params)}`)
      .then(res => {
        this.setContents(res)
        successHandler(res)
      })
      .finally(() => this.setLoading(false))
  }

  @action getUsersAutoComplete = (param, successHandler = () => {}) => {
    const req = {searchValue: param, searchCategory: 'username'}
    this.sortedMaxRequestTemplate('/api/users', 0, 200, req, (res) => {
      successHandler(res.data.content)
    })
  }

  @action changePassword = (params, successHandler = () => {}, failHandler = () => {}) => {
    this.patchRequestTemplate('/api/users/password', params, successHandler, failHandler)
  }

  @action updateMailPageSize = (param = {}, successHandler = () => {}, errorHandler = () => {}) => {
    this.patchRequestTemplate(`/api/users/pagesize`, param, (res) => {
      successHandler && successHandler(res.data)
      this.settings = {
        ...this.settings,
        mailPageSize: param.mailPageSize
      }
    }, (error) =>{
      errorHandler && errorHandler(error)
    })
  }
}

export default UserStore
