import NormalizedStore from '../NormalizedStore';
import { action, makeObservable, observable } from 'mobx';

class AddressGroupStore extends NormalizedStore {
  constructor() {
    super();
    makeObservable(this)
  }

  @observable isAddGroup = false
  @observable isUpdateDialogOpen= false // sidebar dialog
  @observable isDelDialogOpen= false    // sidebar dialog
  @observable isSideBar = false

  @action setIsAddGroup = (isAdded) => {this.isAddGroup = isAdded}

  @action setIsDelDialogOpen = (isOn) => {
    this.isDelDialogOpen = isOn
  }

  @action setIsUpdateDialogOpen = (isOn) => {this.isUpdateDialogOpen = isOn}

  @action setIsAddDialogOpen = (isOn) => {this.isAddDialogOpen = isOn}

  @action setIsSideBar = (param) => {
    this.isSideBar = param
  }

  @action getAllAddressGroup = async (params = {}, successHandler) => {
    this.sortedRequestTemplate(
      '/api/address/group',
      params,
      (res) => {
        this.setContents(res)
        successHandler(res)
      },
      () => {}
    )
  }

  @action saveGroup = (param, successHandler, failHandler) => {
    this.postRequestTemplate(
      '/api/address/group',
      param,
      (res) => {
        this.setIsAddGroup(!this.isAddGroup)
        successHandler()
      },
      failHandler
    )
  }

  @action updateGroupName = async (params = {}) => {
    this.postRequestTemplate(
      '/api/address/group/name',
      params,
      () => {
        this.setIsAddGroup(!this.isAddGroup)
      },
      () => {
        const {message} = error['response']['data']
      }
    )
  }

  @action delGroup = async (params = {}, successHandler, failHandler) => {
    this.getRequestTemplate(
      `/api/address/group/del/${params.id}`,
      {},
      (res) => {
        this.setIsAddGroup(!this.isAddGroup)
        successHandler(res)
      },
      failHandler
    )
  }
}

export default AddressGroupStore