import { action, computed, makeObservable, observable } from 'mobx';
import NormalizedStore from "src/store/NormalizedStore";
import {ColumnType} from "src/constants/AppConst";
import axios from "axios";
import moment from "moment";
import {label, desc} from "src/constants/ResourceBundle";
import _ from "lodash";

class MailStore extends NormalizedStore {
  constructor() {
    super('receivers.receiveDt')
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['title'],
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      label: ['createDt'],
      field: 'create_dt',
      type: ColumnType.DATETIME,
      default: true
    }
  ]

  columns = [
    // {
    //   cellId: 'id',
    //   label: ['id'],
    //   useSort: true,
    // },
    {
      cellId: 'create_dt',
      label: ['createDt'],
      useSort: true,
    },
    {
      cellId: 'actions',
      label: ['detail'],
    },
  ]

  @observable _userInfo = null
  @observable mailDetail = null
  @observable parentsMails = null
  @observable searchType = "all"
  @observable moreOptionAnchorEl = null
  @observable _usingInboxSize = 0

  getContentToForward = (mail) => {
    // todo 토스트 UI 로 변경한 이후에 문자열 변경을 통한 방법을 찾지 못하고 있음
    const receivers = this.getReceiveUsernames(mail)
    let content = ''
    // content += newline + ' '
    // content += '- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -<br>'
    // content += `from: ${this.getSendUsername(mail)}<br>`
    // content += `to: ${receivers.goal}<br>`
    // content += `ref: ${receivers.ref}<br>`
    // content += `date: ${this.getFormattedTime(mail.sendDt)}<br><br>`
    content += `${mail.content}`
    // content += `<br><br>`

    return content
  }

  createForwardContent = () => {
    let result = ''

    if (this.parentsMails) {
      this.parentsMails.forEach(mail => {
        result = this.getContentToForward(mail) + result
      })
    }
    result = this.getContentToForward(this.mailDetail) + result
    // result = '<br><br><br><br><br>' + result

    return result
  }

  @computed get computedContent () {
    return this.content;
  }

  @action setParents = (params) => {
    this.parentsMails = params
  }

  @action setSearchType = (param) => {
    this.searchType = param;
  }

  @computed get userInfo() {
    return this._userInfo
  }

  @computed get userInfoExist() {
    console.debug('userInfo ?? ', this._userInfo)
    return null !== this._userInfo;
    // return false
  }

  @action setMoreOptionAnchorEl(event) {
    this.moreOptionAnchorEl = event ? event.currentTarget : null
  }

  @action mailInfo(user) {
    return `${user.userDetail.lastName}${user.userDetail.firstName}<${user.email}>`
  }

  @computed get sendUsername() {
    return this.mailDetail ?
      this.mailInfo(this.mailDetail.user)
      : null
  }

  getSendUsername = (mailDetail) => {
    return mailDetail ?
        this.mailInfo(mailDetail.user)
        : null
  }

  getReceiveUsernames = (mailDetail) => {
    let goalReceivers = []
    let refReceivers = []

    mailDetail.receivers.forEach(receiver => {
      switch (receiver.referenceType) {
        case 'goal':
          goalReceivers.push(this.mailInfo(receiver.user))
          break
        case 'ref':
          refReceivers.push(this.mailInfo(receiver.user))
          break
        default:
          break
      }
    })

    return {
      goal: goalReceivers.join(', '),
      ref: refReceivers.join(', ')
    }
  }

  getReceiveUsersByRefType = (refType) => {
    const myUserId = this.mailDetail.myInfo.user.id;

    return this.mailDetail ?
      this.mailDetail.receivers
        .filter(receiver => receiver.referenceType === refType && myUserId !== receiver.user.id)
        .map(receiver => receiver.user)
      : []
  }

  getGoalUsersToReply = () => {
    return _.uniqBy([...this.getReceiveUsersByRefType('goal'), this.mailDetail.user], 'id')
  }

  getRefUsersToReply = () => {
    return _.uniqBy(this.getReceiveUsersByRefType('ref'), 'id')
  }

  getReceiveUsersByType = (mail, refType) => {
    return mail.receivers
      .filter(receiver => receiver.referenceType === refType)
      .map(receiver => receiver.user)
  }

  @action setUserInfo = (userObj) => {
    this._userInfo = userObj
  }

  @action setMailDetail = (mail) => {
    if(_.isNil(mail.content)){
      this.getContent(mail, (res) => {
        mail.content = String(res.data);
        this.mailDetail = mail;
      })
    } else {
      this.mailDetail = mail;
    }
  }

  @action closeDetail = () => {
    this.mailDetail = null
  }

  @computed get isDetailOpen() {
    return null !== this.mailDetail
  }

  @computed get isNoSelectMail() {
    return null === this.mailDetail
  }

  @computed get hasResponseHistory() {
     return null !== this.mailDetail
        && null !== this.mailDetail.pid
  }

  @computed get usingInboxSize() {
    return this._usingInboxSize
  }

  @action setStarYn = (receivedMail, starYn) => {
    receivedMail.starYn = starYn
  }

  @action setStarYnAll = (receivedMails, starYn) => {
    receivedMails.forEach(receivedMail => receivedMail.starYn = starYn)
  }

  @action setReadDisplayYn = (receivedMail, readDisplayYn) => {
    receivedMail.readDisplayYn = readDisplayYn
  }

  @action setReadDisplayYnAll = (receivedMails, readDisplayYn) => {
    receivedMails.forEach(receivedMail => receivedMail.readDisplayYn = readDisplayYn)
  }

  // todo nomalize 필요
  @action sendMailWithFiles = (param, files = [], successHandler = () => {}, errorHandler = () => {}) => {
    const formData = new FormData();

    !_.isNil(files) && files.forEach(file => formData.append("files", file))

    formData.append('mailEntity', new Blob(
        [JSON.stringify(param)],
        {type: "application/json"}
    ));

    axios.post(
        '/api/mail/withFile/',
        formData,
        {headers: {"Content-Type": undefined}}
    )
        .then(() => {
          successHandler && successHandler()
        })
        .catch(() => {
          errorHandler && errorHandler()
        })
  }

  @action saveDraftMail = (params, successHandler, errorHandler = () => {}) => {
    this.postRequestTemplate('/api/mail/draft', params, (res) => {
      this.setContent(res)
      successHandler(res.data)
    }, (error) => {
      errorHandler(error);
    })
  }

  @action checkStar = async (params, successHandler = () => {}) => {
    this.putRequestTemplate(`/api/mail/${params.id}/received/star/${params.receiveId}`, successHandler)
  }

  @action checkStars = async (params = {}, successHandler = () => {}) => {
    this.postRequestTemplate(
      '/api/mail/received/star',
      params.map(receivedMail => receivedMail.id),

      () => {
        successHandler()
        this.setStarYnAll(params, 'Y')
      })
  }

  @action uncheckStar = async (params, successHandler = () => {}) => {
    this.putRequestTemplate(`/api/mail/${params.id}/received/cancel-star/${params.receiveId}`, successHandler)
  }

  @action uncheckStars = async (params = {}, successHandler = () => {}) => {
    this.postRequestTemplate(
      '/api/mail/received/cancel-star',
      params.map(receivedMail => receivedMail.id),
      () => {
        successHandler()
        this.setStarYnAll(params, 'N')
      })
  }

  @action read = async (params, successHandler = () => {}) => {
    this.putRequestTemplate(`/api/mail/${params.id}/received/read/${params.receiveId}`, successHandler)
  }

  @action readMails = async (params = {}, successHandler = () => {}) => {
    this.postRequestTemplate(
      '/api/mail/received/read',
      params.map(receivedMail => receivedMail.id),
      () => {
        successHandler()
        this.setReadDisplayYnAll(params, 'Y')
      }
    )
  }

  @action unread = async (params, successHandler = () => {}) => {
    this.putRequestTemplate(`/api/mail/${params.id}/received/unread/${params.receiveId}`, successHandler)
  }

  @action unreadMails = async (params = {}, successHandler = () => {}) => {
    this.postRequestTemplate('/api/mail/received/unread',
      params.map(receivedMail => receivedMail.id),
      () => {
        successHandler()
        this.setReadDisplayYnAll(params, 'N')
      }
    )
  }

  @action moveToTrash = async (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate(`/api/mail/trash/${param}`, null, successHandler, errorHandler)
  }

  @action moveManyToTrash = async (params = {}, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate('/api/mail/trash', params, successHandler, errorHandler)
  }

  @action deleteMail = async (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.deleteRequestTemplate(`/api/mail/trash/delete/${param}`, successHandler, errorHandler)
  }

  @action deleteMails = async (params, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate(`/api/mail/trash/delete`, params, successHandler, errorHandler)
  }

  @action deleteDraftMails = async (params, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate(`/api/mail/drafts/delete`, params, successHandler, errorHandler)
  }

  @action emptyTrash = async (successHandler = () => {}, errorHandler = () => {}) => {
    this.deleteRequestTemplate(`/api/mail/trash/delete/all`, successHandler, errorHandler)
  }

  @action restore = async (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.patchRequestTemplate(`/api/mail/restore/${param}`, null, successHandler, errorHandler)
  }

  @action restoreMany = async (params, successHandler = () => {}, errorHandler = () => {}) => {
    this.postRequestTemplate(`/api/mail/restore`, params, successHandler, errorHandler)
  }

  @action getParents = (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.sortedRequestTemplate(
        `/api/mail/${param}/parents`,
      null,
        (res) => {
          successHandler(res.data)
          this.setParents(res.data.content)
        },
        errorHandler)
  }

  @action cancelReservation = (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.deleteRequestTemplate(
      `/api/mail/reservation/${param}`,
      successHandler,
      errorHandler)
  }

  @action cancelSending = (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.putRequestTemplate(
      `/api/mail/cancel/${param}`,
      successHandler,
      errorHandler)
  }

  @action cancelSendingForSpecificReceiver = (param, successHandler = () => {}, errorHandler = () => {}) => {
    this.putRequestTemplate(
      `/api/mail/cancel/${param.mailId}/receiver/${param.receiveId}`,
      successHandler,
      errorHandler)
  }

  @action getUsingInboxSize = () => {
    this.getRequestTemplate(
      '/api/mail/using-size', null, (res) => {
        this._usingInboxSize = Math.ceil(res.data * 10 / (1024 * 1024)) / 10
      }
    )
  }

  @action getContent = (pMail, successHandler = () => {}, errorHandler = () => {}) => {
    this.getRequestTemplate(`/api/mail/${pMail.id}/content`,
      null,
      (res) => successHandler(res),
      errorHandler
    )
  }

  mailParentsGenerator = (mail) => {
    if(_.isNil(mail.parentMap)){
      return {
        mailId: mail.id,
      }
    }

    const parentMap =  mail.parentMap
    parentMap.push({mailId: mail.id})
    return parentMap
  }

  isNowDay = (datetime) => {
    const nowDate = new Date()
    const compareDate = new Date(datetime)

    return compareDate.getFullYear() === nowDate.getFullYear() &&
      compareDate.getMonth() === nowDate.getMonth() &&
      compareDate.getDate() === nowDate.getDate()
  }

  getFormattedTime = datetime => {
    if (undefined === datetime || null === datetime) {
      return null
    }

    return moment(datetime).format('YYYY-MM-DD HH:mm')
  }

  getFormattedTimeForList = datetime => {
    if (undefined === datetime || null === datetime) {
      return null
    }

    return this.isNowDay(datetime) ?
      moment(datetime).format('HH:mm') : moment(datetime).format('YYYY-MM-DD HH:mm')
  }

  getUsername = (user) => {
    if(_.isNil(user) || _.isNil(user.userDetail) || _.isNil(user.userDetail.lastName)) {
      return
    }
    return `${user.userDetail.lastName}${user.userDetail.firstName}`
  }

  getBriefReceiversInfo = (mail) => {
    let result = 'drafts'

    if (mail.receivers.length > 0) {
      result = `${this.getUsername(mail.receivers[0].user)}`
    }

    if (mail.receivers.length-1 > 0) {
      result += ` 외 ${mail.receivers.length - 1} 명`
    }
    return result
  }

  getReadInfo = (mail) => {
    return this.getReserveReceiveConfirmLabel(mail)
      || this.getSingleReceiveConfirmLabel(mail)
      || this.getMultiReceiveConfirmLabel(mail)
  }

  isSingleReceiver = (mail) => {
    return mail.receivers.length === 1
  }

  getSingleReceiver = (mail) => {
    return this.isSingleReceiver ? mail.receivers[0] : null
  }

  getReserveReceiveConfirmLabel = (mail) => {
    return 'reserve' === mail.status ? label('send_wait') : null
  }

  getSingleReceiveConfirmLabel = (mail) => {
    if (this.isSingleReceiver(mail)) {
      return this.getSingleReadInfo(this.getSingleReceiver(mail))
    }
  }

  getSingleReadInfo = (receiver) => {
    return 'N' === receiver.receiveYn ? label('send_fail') :
      'Y' === receiver.sendCancelYn ? label('send_cancel') :
        'Y' === receiver.readYn ? label('read') :
          label('not_read')
  }

  getMultiReceiveConfirmLabel = (mail) => {
    if (mail.receivers.every(receiver => 'N' === receiver.receiveYn)) {
      return label('send_fail')
    }
    return desc('mail_read_users_count', mail.receivers.filter(receiver => 'Y' === receiver.readYn).length)
  }

  isPossibleCancelSending = (mail) => {
    return 'reserve' === mail.status
      || mail.receivers
        .filter(receiver => 'Y' !== receiver.readYn && 'N' === receiver.sendCancelYn)
        .length > 0
  }
}

export default MailStore
