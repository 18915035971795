import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const CaseRoute = [
  {
    path: '/app/management/case',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/management/case/new',
        component: lazy(() => import('src/views/case/CaseEditView'))
      },
      {
        exact: true,
        path: '/app/management/case',
        component: lazy(() => import('src/views/case/CaseListView'))
      },
    ]
  }
]

export default CaseRoute
