import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";
import { Redirect } from 'react-router';

const MailRoute = [
  {
    path: '/app/management/mail',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        path: [
              '/app/management/mail/label/:customLabel/:mailId?',
              '/app/management/mail/:systemLabel/:mailId?',
              '/app/management/mail/detail/:mailId?'
            ],
        // path: '/app/management/mail/all',
        component: lazy(() => import('src/views/mail/MailView'))
      },

      {
        exact: true,
        guard: AuthGuard,
        path: '/app/management/mail/detail/:id',
        component: lazy(() => import('src/views/mail/MailView/MailDetails'))
      },
      // {
      //   exact: true,
      //   guard: AuthGuard,
      //   path: '/app/management/mail/detail/:id',
      //   component: lazy(() => import('src/views/mail/UserDetailView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/mail',
      //   component: () => <Redirect to="/app/mail/all" />
      // },
    ]
  }
]

export default MailRoute
