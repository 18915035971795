import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import GlobalStyles from 'src/components/GlobalStyles'
import ScrollReset from 'src/components/ScrollReset'
import useSettings from 'src/hooks/useSettings'
import { createTheme } from 'src/theme'
import routes, { renderRoutes } from 'src/routes'
import axios from 'axios'
import CommonDialog from './components/common/CommonDialog'
import CommonSnackbar from './components/common/CommonSnackbar'

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();


const appendScript = (scriptUrl) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = scriptUrl;
  document.body.appendChild(script);
};

// appendScript("/api/bundle/codes");
appendScript("/api/bundle/messages");
appendScript("/api/bundle/labels");

const App = () => {

  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <Router history={history}>
              {/*<AuthProvider>*/}
                <GlobalStyles />
                <ScrollReset />
                <CommonDialog />
                <CommonSnackbar />
                {/*<GoogleAnalytics />*/}
                {/*<CookiesNotification />*/}
                {/*<SettingsNotification />*/}
                {renderRoutes(routes)}
              {/*</AuthProvider>*/}
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
