import {action, computed, makeObservable, observable} from 'mobx';
import axios from 'axios';
import NormalizedStore from 'src/store/NormalizedStore';
import {paramParser} from 'src/utils/paramParser';
import {getIcon} from 'src/utils/IconUtil';
import {
  Activity as ActivityIcon,
  PieChart as PieChartIcon,
} from 'react-feather';
import React from 'react';
import {empty} from 'src/utils/objectUtils';
import {Mail as MailIcon}  from '@material-ui/icons';

class MenuStore extends NormalizedStore {

  constructor() {
    super()
    makeObservable(this)
    this.baseUrl = '/api/common/'

    // todo kckwon 메뉴 db에서 관리하기
    this.sections = [
      {
        subheader: '메일',
        isAdminOnly: false,
        items: [
          {
            title: '메일',
            icon: MailIcon,
            href: '/app/management/mail/all',
          },
        ]
      },
      {
        subheader: '관리',
        isAdminOnly: true,
        items: [
          {
            title: '사용자관리',
            icon: ActivityIcon,
            href: '/app/management/user',
            items: [
              {
                title: '사용자목록',
                href: '/app/management/user'
              },
              {
                title: '관리자목록',
                href: '/app/management/admin'
              },
            ]
          },
        ]
      },
    ]
  }

  @observable activeMenu = []
  @observable menu = {}
  @observable selectedSuperMenu = {}

  @computed get formattedActiveMenu() {
    return 0 !== this.activeMenu.length ?
      this.activeMenu
        .map(content => {
          return (
            {
              subheader: content.menuName,
              items: content.menusEntities
                .filter(subMenu => 'Y' === subMenu.useYn)
                .map(subMenu => {
                  return ({
                    title: subMenu.menuName,
                    icon: null !== subMenu.icon ? getIcon(subMenu.icon) : null,
                    href: null !== subMenu.menuLink ? subMenu.menuLink : ''
                  });
                })
            })
        })
      : this.activeMenu
  }

  get mergedSections() {
    return empty(this.formattedActiveMenu) ? this.sections : this.sections.concat(this.formattedActiveMenu)
  }

  @action getActiveMenus = () => {
    /*axios.get(`${this.baseUrl}menu?useYn=Y`)
      .then(res => {
        this.activeMenu = res.data.content
      })*/
  }

  @action setSelectedSuperMenu = (params = {}) => {
    this.selectedSuperMenu = params
  }

  @action getMenus = (params = {}, successHandler={}, failHandler = () => {}) => {
    this.setLoading(true)
    axios.get(`${this.baseUrl}menu?${paramParser(params)}`)
      .then(res => {
        this.setContents(res)
        successHandler(res)
      })
      .catch(error => {
        failHandler()
      }).finally(() => {
      this.setLoading(false)
    })
  }

  @action getMenu = (params = {}, successHandler= () => {}, failHandler = () => {}) => {
    this.setLoading(true)
    axios.get(`${this.baseUrl}menu/${params.id}?${paramParser(params.menu)}`)
      .then(res => {
        // this.menu = res.data
        successHandler(res.data)
      })
      .catch(error => {
        failHandler()
      }).finally(() => {
      this.setLoading(false)
    })
  }

  @action getFullMenus = (params = {}) => {
    this.setLoading(true)
    axios.get(`${this.baseUrl}menu/full`)
      .then(res => {
        this.setContents(res)
      })
      // .catch(error ={})
      .finally(() => {this.setLoading(false)})
  }

  @action saveMenu = (menu, successHandler, failHandler) => {
    this.setLoading(true)
    axios.post(`${this.baseUrl}menu`, menu)
      .then(res => {
        successHandler()
      })
      .catch(error => {
        failHandler()
      })
      .finally(() => this.setLoading(false))
  }

  @action deletesMenus = (menu, successHandler, failHandler) => {
    this.setLoading(true)
    axios.post(`${this.baseUrl}menu/deletes`, menu)
      .then(res => {
        successHandler()
      })
      .catch(error => {
        failHandler()
      })
      .finally(() => this.setLoading(false))
  }

}
export default MenuStore
