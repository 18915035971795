/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
/**
 * 반올림
 *
 * @param intPosition - 반올림할 자릿수
 */
Number.prototype.round = function (intPosition) {
    try {
        var decimalPosition = Math.pow(10.0, intPosition);
        var returnValue = (Math.round(this * decimalPosition)) / decimalPosition;
        return returnValue;
    } catch (e) {
    }
};

/**
 * 올림
 *
 * @param intPosition - 올림할 자릿수
 */
Number.prototype.ceil = function (intPosition) {
    try {
        var decimalPosition = Math.pow(10.0, intPosition);
        var returnValue = (Math.ceil(this * decimalPosition)) / decimalPosition;
        return returnValue;
    } catch (e) {
    }
};

/**
 * 내림
 *
 * @param intPosition - 내림할 자릿수
 */
Number.prototype.floor = function (intPosition) {
    try {
        var decimalPosition = Math.pow(10.0, intPosition);
        var returnValue = (Math.floor(this * decimalPosition)) / decimalPosition;
        return returnValue;
    } catch (e) {
    }
};

/**
 * 짝수여부 확인
 */
Number.prototype.isEven = function () {
    return this % 2 === 0 ? true : false;
};

/**
 * 절대값 반환 - 정수
 */
Number.prototype.abs = function () {
    return Math.abs(this);
};

/**
 * 랜덤값 반환 - 정수
 */
Number.prototype.random = function () {
    return (Math.random() * this).round(0);
};

Number.prototype.toMoney = function () {
    var num = this.toString();
    var pattern = /(-?[0-9]+)([0-9]{3})/;
    while (pattern.test(num)) {
        num = num.replace(pattern, "$1,$2");
    }
    return num;
}

Number.prototype.zf = function (len) {
    return this.toString().zf(len);
};

Number.prototype.toDate = function (pattern) {
    const now = new Date(this)
    return now.format(pattern)
}
