import { action, computed, makeObservable, observable } from "mobx";
import NormalizedStore from "src/store/NormalizedStore";
import { labels } from "src/constants/ResourceBundle";
import { ColumnType } from "src/constants/AppConst";

class MessageStore extends NormalizedStore {

  constructor() {
    super()
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['title'],
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      label: ['message'],
      field: 'content',
      type: ColumnType.STRING,
      default: true
    },
    {
      label: ['createDt'],
      field: 'create_dt',
      type: ColumnType.DATETIME,
    }
  ]

  columns = [
    {
      cellId: 'title',
      label: ['title'],
      useSort: true,
    },
    {
      cellId: 'receive_group',
      label: ['receive', 'group'],
      // useSort: true,
    },
    {
      cellId: 'receive_user',
      label: ['receiver'],
    },
    {
      cellId: 'send_state',
      label: ['send', 'state'],
    },
    {
      cellId: 'create_dt',
      label: ['createDt'],
    },
    {
      cellId: 'actions',
      label: ['detail'],
    },
  ]

  @observable messages

  @action getMessage = (params = {}) => {
    this.sortedRequestTemplate('/api/message', params, (res) => {
      this.setContents(res)
    })
  }

  @computed get formattedContents () {
    const tempContents =  this.contents.map(content => {
      content.receiveGroup = `${content.receiveGroup[0].name} ${labels('except')} ${content.receiveGroup.length - 1}`
      content.receiveUser = `${content.receiveUser[0].celNo} ${labels('except')} ${content.receiveUser.length - 1}`
      content.sendState = this.setSendState(content.sendState)
      content.isContentSelected = false
      return content
    })
    return tempContents
  }

  setSendState = (states = []) => {
    const totalCount = states.length
    const sentCount = states.filter(state => state.isSent).length
    return `${sentCount} / ${totalCount}`
  }
}

export default MessageStore
