const stringTemplate = (strings, ...keys) => {
  return (function (...values) {
    const dict = values[values.length - 1] || {}
    const result = [strings[0]]
    keys.forEach(function (key, i) {
      const value = Number.isInteger(key) ? values[key] : dict[key]
      result.push(value, strings[i + 1])
    })
    return result.join('')
  })
}

const keyToValue = (keys) => {
  return keys.map(key => {
    const msgCd = `label.${key}`
    return (undefined === LABEL[msgCd] || null === LABEL[msgCd]) ? key : LABEL[msgCd]
  })
}

const get = (prefix, cd, ...keys) => {
  try{
    const msgCd = `${prefix}.${cd}`
    const msg = (undefined === LABEL[msgCd] || null === LABEL[msgCd]) ? msgCd : LABEL[msgCd]
    return eval("stringTemplate`" + msg.replace(/{/ig, '${') + "`")(...keyToValue(keys))
  } catch (e) {
    // console.debug(`There is no label : ${cd}`)
    return ''
  }
}

export const message = (msgCd, ...keys) => {
  try{
    const message = (undefined === MESSAGE[msgCd] || null === MESSAGE[msgCd]) ? msgCd : MESSAGE[msgCd]
    return eval("stringTemplate`" + message.replace(/{/ig, '${') + "`")(...keyToValue(keys))
  } catch(e) {
    // console.debug(`There is no message. : ${msgCd}`)
    return ''
  }
}

export const label = (cd, ...keys) => {
  return get('label', cd, ...keys)
}

export const labels = (...cds) => {
  return cds.reduce((clabels, cd) => {
    return clabels.concat(label(cd))
  }, []).join(' ')
}

export const desc = (cd, ...keys) => {
  return get('desc', cd, ...keys)
}

export const code = {
  value: (codeGroup, cd) => {
    try{
      const code = CODEMAP[codeGroup][cd]
      return (undefined === code || null === code) ? cd : code
    } catch (e) {
      // console.debug(`There is no code : ${codeGroup} => ${cd}`)
      return ''
    }
  },
  group: (codeGroup) => {
    try{
      const codes = CODEARR[codeGroup]
      return (undefined === codes || null === codes) ? [] : codes
    } catch (e) {
      // console.debug(`There is no code group : ${codeGroup} `)
      return []
    }
  },
  arrToInt: (codeGroup) => {
    try{
      const codes = CODEARR[codeGroup].map(code => {
        return parseInt(code.cd)
      })
      return codes
    } catch (e) {
      // console.debug(`We've got error causes by ${e}`)
      return []
    }
  }
}

export const pageOf = (options = {}) => {
  return `${options.from}-${options.to} ... ${options.count}`
}
