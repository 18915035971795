import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {inject, observer} from "mobx-react";

const AuthGuard = ({ children, store }) => {

  const { authStore } = store
  const { isAuthenticated, isValidToken } = authStore;

  // if (!isValidToken) {
  //   return <Redirect to="/login" />;
  // }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default inject('store')(observer(AuthGuard));
