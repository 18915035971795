import {action, computed, makeObservable, observable} from "mobx";
import NormalizedStore from "src/store/NormalizedStore";
import {ColumnType} from "src/constants/AppConst";

class VoteStore extends NormalizedStore {

    constructor() {
        super()
        makeObservable(this)
    }

    searchCategories = [
        {
            label: ['title'],
            field: 'title',
            type: ColumnType.STRING,
        },
        {
            label: ['createDt'],
            field: 'create_dt',
            type: ColumnType.DATETIME,
            default: true
        }
    ]

    columns = [
        {
            cellId: 'auth_code',
            label: ['auth', 'code'],
        },
        {
            cellId: 'answers',
            label: ['vote', 'value'],
        },
        {
            cellId: 'vote_date',
            label: ['vote', 'datetime'],
        },
        {
            cellId: 'voterName',
            label: ['voter', 'name'],
        },
        {
            cellId: 'celNo',
            label: ['celno'],
        },
        {
            cellId: 'create_dt',
            label: ['createDt'],
            useSort: true,
        },
        {
            cellId: 'actions',
            label: ['link', 'create'],
        },
    ]

    @observable caseObj = {}
    @observable isVoteEditView = false

    @action setIsVoteEditView = (param) => {
        this.isVoteEditView = param
    }

    @action setCaseObj = (param) => {
        this.caseObj = param
    }

    @action save = (param ={}, successHandler = () => {}, failHandler = () => {}) => {
        this.postRequestTemplate('/api/vote',
            param,
            (res) => successHandler(res),
            (error) => failHandler(error)
        )
    }

    @action getVoteList = (param ={}, successHandler = () => {}, failHandler = () => {}) => {
        this.sortedRequestTemplate('/api/vote', param, (res) => {
            this.setContents(res)
            successHandler(res)
        })
    }
}

export default VoteStore
