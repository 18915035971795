/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
/**
 * 공백제거
 */
String.prototype.trim = function () {
    return this.replace(/(^\s*)|(\s*$)/g, "");
};

/**
 * 메일\ 유효성 확인
 */
String.prototype.isMail = function () {
    // eslint-disable-next-line no-useless-escape
    let em = this.trim().match(/^[_\-\.0-9a-zA-Z]{3,}@[-.0-9a-zA-z]{2,}\.[a-zA-Z]{2,4}$/);
    return (em) ? true : false;
};

/**
 * 주민번호 형식확인(XXXXXXX-XXXXXXXX)
 */
String.prototype.isRegNum = function () {
    let num = this.trim().onlyNum();
    if (num.length === 13) {
        num = num.substring(0, 6) + "-" + num.substring(6, 13);
    } else {
        return false;
    }
    num = num.match(/^([0-9]{6})-?([0-9]{7})$/);
    if (!num) return false;
    let num1 = RegExp.$1;
    let num2 = RegExp.$2;
    if (!num2.substring(0, 1).match(/^[1-4]{1}$/)) return false;
    num = num1 + num2;
    let sum = 0;
    let last = num.charCodeAt(12) - 0x30;
    let bases = "234567892345";
    for (let i = 0; i < 12; i++) {
        sum += (num.charCodeAt(i) - 0x30) * (bases.charCodeAt(i) - 0x30);
    }
    let mod = sum % 11;
    return ((11 - mod) % 10 === last) ? true : false;
};

/**
 * 전화번호 확인 (XXX-XXXX-XXXX)
 */
String.prototype.isPhone = function () {
    let num = this.trim().onlyNum();
    if (num.substring(1, 2) === "2") {
        num = num.substring(0, 2) + "-" + num.substring(2, num.length - 4) + "-" + num.substring(num.length - 4, num.length);
    } else {
        num = num.substring(0, 3) + "-" + num.substring(3, num.length - 4) + "-" + num.substring(num.length - 4, num.length);
    }
    num = num.match(/^0[0-9]{1,2}-[1-9]{1}[0-9]{2,3}-[0-9]{4}$/);
    return (num) ? true : false;
};

/**
 * 핸드폰 확인 (XXX-XXXX-XXXX)
 */
String.prototype.isMobile = function () {
    let num = this.trim().onlyNum();
    num = num.substring(0, 3) + "-" + num.substring(3, num.length - 4) + "-" + num.substring(num.length - 4, num.length);
    num = num.trim().match(/^01[016789]{1}-[1-9]{1}[0-9]{2,3}-[0-9]{4}$/);
    return (num) ? true : false;
};

/**
 * 숫자여부 확인
 */
String.prototype.isNum = function () {
    return (this.trim().match(/^[0-9]+$/)) ? true : false;
};

/**
 * 영문여부 확인
 */
String.prototype.isEng = function () {
    return (this.trim().match(/^[a-zA-Z]+$/)) ? true : false;
};

/**
 * 영문+숫자여부 확인
 */
String.prototype.isEngNum = function () {
    return (this.trim().match(/^[0-9a-zA-Z]+$/)) ? true : false;
};

/**
 * 한글여부 확인
 */
String.prototype.isKor = function () {
    return (this.trim().match(/^[ㄱ-ㅎ|ㅏ-ㅣ|가-힝|]+$/)) ? true : false;
};

/**
 * 한글+숫자 확인
 */
String.prototype.isKorNum = function () {
    return (this.trim().match(/^[ㄱ-ㅎ|ㅏ-ㅣ|가-힝|0-9]+$/)) ? true : false;
};

/**
 * 문자+숫자 확인
 */
String.prototype.isStrNum = function () {
    return (this.trim().match(/^[ㄱ-ㅎ|ㅏ-ㅣ|가-힝|a-zA-Z|0-9|-]+$/)) ? true : false;
};

/**
 * 문자 확인
 */
String.prototype.isStr = function () {
    return (this.trim().match(/^[ㄱ-ㅎ|ㅏ-ㅣ|가-힝|a-zA-Z]+$/)) ? true : false;
};

/**
 * 날짜 확인
 */
String.prototype.isDate = function () {
    return (this.trim().match(/^[-0-9]+$/)) ? true : false;
};

/**
 * 특수문자제외 확인
 */
String.prototype.hasSpecialChar = function () {
    // eslint-disable-next-line no-useless-escape
    return (this.trim().match(/[@\,<>\"\']+$/)) ? true : false;
};

/**
 * 숫자(실수) 이외의 문자열 제거
 */
String.prototype.toNum = function () {
    let num = this.trim();
    // eslint-disable-next-line no-useless-escape
    return num.replace(/[^0-9\.-]/g, "");
};

/**
 * 숫자(양의 정수) 이외의 문자열 제거
 */
String.prototype.onlyNum = function () {
    return (this.trim().replace(/[^0-9]/g, ""));
};

/**
 * 수 형태의 문자열 제거
 */
String.prototype.noNum = function () {
    return (this.trim().replace(/[0-9]/g, ""));
};

/**
 * 통화유형으로 변환
 */
String.prototype.toMoney = function () {
    let num = this.toNum();
    let pattern = /([0-9]+)([0-9]{3})/;
    while (pattern.test(num)) {
        num = num.replace(pattern, "$1,$2");
    }
    return num;
};

/**
 * 통화유형여부 확인
 */
String.prototype.isCurrency = function () {
    if (new RegExp(/^[0-9,]+$/).test(this)) {
        return true;
    } else {
        return false;
    }
};

/**
 * 문자열 길이 반환(한글:2, 영어:1)
 */
String.prototype.getByteLength = function () {
    let tmplen = 0;
    for (let i = 0; i < this.length; i++) {
        if (this.charCodeAt(i) > 127)
            tmplen += 2;
        else
            tmplen++;
    }
    return tmplen;
};

/**
 * 파일 확장자 반환
 */
String.prototype.getExt = function () {
    let ext = this.substring(this.lastIndexOf(".") + 1, this.length);
    return ext;
};

/**
 * 파일명 반환
 */
String.prototype.getFileName = function () {
    let fileName = this.substring(0, this.lastIndexOf("."));
    return fileName;
};

/**
 * String에 따라서 받침이 있으면 은|이|을 을 받침이 없으면 는|가|를 등을 리턴 한다.
 *
 * @param nm - String (조사간 구분자)
 * @usage str.getSuffix("을/를") : 구분자는 항상 "/"로
 */
String.prototype.getSuffix = function (nm) {
    let nm1 = nm.trim().substring(0, nm.trim().indexOf("/"));
    let nm2 = nm.trim().substring(nm.trim().indexOf("/") + 1, nm.trim().length);
    let a = this.substring(this.length - 1, this.length).charCodeAt();
    a = a - 44032;
    let jongsung = a % 28;
    return (jongsung) ? nm1 : nm2;
};

/**
 * 날짜유형으로변환
 *
 * @param seperator - String 구분자
 */
String.prototype.toDate = function (seperator) {
    seperator = seperator || "-";
    if (this.length !== 8) {
        return "";
    } else {
        return (this.substring(0, 4) + seperator + this.substring(4, 6) + seperator + this.substring(6, 8));
    }
};

/**
 * 생년월일변환(yy[seperator]MM[seperator]dd)
 *
 * @param seperator - String 구분자
 */
String.prototype.toBirth = function (seperator) {
    seperator = seperator || "";
    if (this.length !== 6) {
        return "";
    } else {
        return (this.substring(0, 2) + seperator + this.substring(2, 4) + seperator + this.substring(4, 6));
    }
};

/**
 * 주민번호 변환
 * @param seperator - String 구분자
 */
String.prototype.toSsn = function (seperator) {
    try {
        seperator = seperator || "-";
        let pattern = new RegExp(/([0-9]{6})([0-9]+)/);
        if (this.length > 7) return;
        if (pattern.test(this)) {
            return this.replace(pattern, "$1" + seperator + "$2");
        }
    } catch (e) {
    }
};

/**
 * 문자열을 len길이 만큼 반환
 * 한글의 경우 2바이트로 계산하며, 글자 중간에서 잘리지 않습니다.
 *
 * @param len - int 원하는 문자열 길이
 */
String.prototype.cut = function (len) {
    let str = this;
    let l = 0;
    for (let i = 0; i < str.length; i++) {
        l += (str.charCodeAt(i) > 128) ? 2 : 1;
        if (l > len) return str.substring(0, i);
    }
    return str;
};
/**
 * 빈문자열 여부확인
 */
String.prototype.isEmpty = function () {
    return (this === "" || !this) ? true : false;
};

/**
 * 문자열 유형을 숫자유형으로 변환
 */
String.prototype.to_i = function () {
    try {
        return parseInt(this, 10);
    } catch (e) {
        return parseInt("0", 10);
    }
};

/**
 * 통화유형을 정수유형으로 변환
 */
String.prototype.currencyToInt = function () {
    return this.replaceAll(".", "").replaceAll(",", "").replaceAll("-", "").toString().to_i();
};


/**
 * String에 일치하는 모든 문자를 교체
 */
String.prototype.replaceAll = function (searchStr, replaceStr) {
    let tmp = this.replace(searchStr, replaceStr);
    while (tmp.indexOf(searchStr) !== -1) {
        tmp = tmp.replace(searchStr, replaceStr);
    }
    return tmp;
};

/**
 * 문자를 실수유형으로 변환
 */
String.prototype.to_f = function () {
    return parseFloat(this);
};

String.prototype.getUrlFromQuery = function () {
    return this.split("?")[0];
};

String.prototype.queryToMap = function (extArr) {
    let arr = this.split("?")[1].split("&");
    if (arr.length < 1) {
        arr = this.split("?")[1];
    }
    if (arr.length < 1) return {};

    let map = {};

    let tempArr;
    for (let i = 0; i < arr.length; i++) {
        tempArr = arr[i].split("=");
        if (tempArr.length < 2) {
            continue;
        }
        if (extArr) {
            if (extArr.isExists(tempArr[0])) continue;
        }
        map[tempArr[0]] = tempArr[1];
    }
    return map;
};

String.prototype.paramToObject = function () {
    let arr = this.split("&");
    if (arr.length < 1) return {};

    let map = {};

    let tempArr;
    for (let i = 0; i < arr.length; i++) {
        tempArr = arr[i].split("=");
        if (tempArr.length < 2) {
            continue;
        }
        map['"' + tempArr[0] + '"'] = tempArr[1];
    }
    return map;
};

/**
 * 문자열 비교
 */
String.prototype.isEqual = function (str) {
    return this === str.toString() ? true : false;
};


/**
 * 문자열 비교 케이스 무시
 */
String.prototype.isIgnoreEqual = function (str) {
    return this.toLowerCase() === str.toString().toLowerCase() ? true : false;
};

/**
 * File size formatter.
 * @param decimals
 * @returns {*}
 */
String.prototype.filesizeformat = function (decimals) {
    let bytes = this;
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
        dm = decimals + 1 || 3,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

String.prototype.firstUpper = function () {
    return this.toUpperCase().substr(0, 1).concat(this.substr(1))
};

String.prototype.firstLower = function () {
    return this.toLowerCase().substr(0, 1).concat(this.substr(1))
};

String.prototype.dashToCamel = function () {
    let words = this.split('-'),
        ret = '';
    words.forEach(function (word) {
        ret += ret.toUpperCase();
    });
    return ret === '' ? this : ret;
};

String.prototype.string = function (len) {
    var s = '', i = 0;
    while (i++ < len) {
        s += this;
    }
    return s;
};

String.prototype.zf = function (len) {
    return "0".string(len - this.length) + this;
};

String.prototype.getTime = function (separator = '/') {
  const date = new Date();
  date.setCustomDate(this.replaceAll(separator, ''))
  return date.getTime()
}
