import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const AdminRoute = [
  {
    path: '/app/management/admin',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/management/admin',
        component: lazy(() => import('src/views/admin/AdminListView'))
      },
    ]
  }
]

export default AdminRoute