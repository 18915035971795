import axios from "axios";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import jwtDecode from "jwt-decode";

const ACCESS_TOKEN = 'accessToken';

class AuthStore {

  @observable isAuthenticated = false
  @observable username = null
  @observable user = {
    username: '',
    name:'',
    avatar: '',
    city: '',
    country: '',
    email: '',
    isPublic: '',
    phone: '',
    state: '',
    tier: '',
    role: []
  }

  constructor() {
    makeObservable(this)
    this.name = 'authStore'
  }

  @computed get isValidToken () {

    const accessToken = localStorage.getItem(ACCESS_TOKEN)

    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  }

  @action setAuthenticated = (state) => {
    this.isAuthenticated = state
  }

  getDecodeValue = () => {
    try {
      const decoded = jwtDecode(localStorage.getItem('accessToken'));
      console.debug('decoded', decoded)
    } catch (e) {
    }
  }

  @action setSession = async (accessToken) => {
    if (accessToken) {
      console.debug('set sessin')
      await localStorage.setItem(ACCESS_TOKEN, accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      await localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  @action login = async (username, password, successHandler, failHandler) => {
    await axios.post('/api/auth/login', {username, password})
      .then(res => {
        runInAction(() => {
          const accessToken = res.headers['authorization']
          this.setSession(accessToken)
          const decoded = jwtDecode(accessToken)
          const { email, firstName, lastName, role } = decoded
          this.username = username
          this.user.name = `${lastName} ${firstName}`
          this.user.email = email
          this.user.role = role
          successHandler && successHandler(res)
          this.setAuthenticated(true)
        })
        //
      })
      .catch(error => {
        console.debug('error', error)
        failHandler && failHandler(error)
      })
  }

  @action logout = async () => {
    await this.setSession(null);
    await this.setAuthenticated(false)
  }

  @action useAuth = () => {
    return this.isAuthenticated
  }

  @computed get sessionUser () {
    try {
      return jwtDecode(localStorage.getItem('accessToken'));
    } catch (e) {
      return this.user
    }
  }

  @computed get isAdmin() {
    return this.sessionUser.role.some(r => 'ROLE_ADMIN' === r.authority)
  }

}

export default AuthStore
