import {action, computed, makeObservable, observable} from 'mobx';
import NormalizedStore from "src/store/NormalizedStore";
import {ColumnType} from "src/constants/AppConst";

class TagManagerStore extends NormalizedStore {
  constructor(root) {
    super('id')
    makeObservable(this)
    this.root = root
  }

  searchCategories = [
    {
      label: ['userid'],
      field: 'userid',
      type: ColumnType.STRING
    },
    {
      label: ['name'],
      field: 'username',
      type: ColumnType.STRING,
      default: true
    },
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      width: 70,
    },
    {
      cellId: 'username',
      label: ['name'],
      useSort: true,
    },
    {
      cellId: 'userDetail.celno',
      label: ['celno'],
      width: 150,

    },
  ]

  @observable tagList = []
  @observable addTagDialogIsOpen = false
  @observable selectedTag = {}
  @observable parentTag = {}
  @observable dialogType = ''

  @computed get testComputed() {
    return this.root.mailFormStore.getIsOpenContactDialog
  }

  @computed get getParentTag() {
    return this.parentTag
  }

  @computed get getTagNames() {
    const getTagTitle = (arr) => {
      const tagNames = []
      arr.forEach(tag => {
        tagNames.push(tag.title)
        !_.isNil(tag.children) && getTagTitle(tag.children)
      })
      return tagNames
    }

    if(_.isNil(this.tagList)) { return []}
    return getTagTitle(this.tagList)
  }

  validateName = (pName) => {
    if(_.isNil(pName)) { return false}
    return -1 === this.getTagNames.findIndex(item => item === pName)
  }

  @action getUserBySelectedTag = (param) => {
    this.selectedTag.id && this.getUsersByTag(this.selectedTag.id, param)
  }

  @action setSelectedTag = (param = {}) => {
    this.selectedTag = param
  }

  @action openAddTagDialog = (item = {}, type) => {
    this.parentTag = item
    this.dialogType = type
    this.addTagDialogIsOpen = true
  }

  @action closeAddTagDialog = () => {
    this.parentTag = {}
    this.dialogType = ''
    this.addTagDialogIsOpen = false
  }

  @action getTagList = () => {
    this.getRequestTemplate(`/api/tag/all`, null , (res) => {
      this.tagList = res.data
    })
  }

  @action getUsersByTag = (pTagId, param = {}) => {
    const req = {
      ...param,
      searchCategory: this.searchCategory ? this.searchCategory.split(':')[0] : '',
      searchValue: this.searchValue || '',
    }

    this.sortedRequestTemplate(`/api/tag/${pTagId}/users`, req, (res) => {
      this.setContents(res)
    })
  }

  @action removeTag = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.deleteRequestTemplate(`/api/tag/delete/${param}`, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action removeUsers = (tagId, params, successHandler =() => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/tag/delete/bulk/${tagId}/users/`, params, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action addUserToTag = (pTagId, pUsers, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/tag/${pTagId}/insert/users`, params, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action updateTag = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.patchRequestTemplate(`/api/tag/${param.id}`, param, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action newTag = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/tag/new`, param, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }

  @action addChild = (param, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate(`/api/tag/${param.id}/addChild`, {title: param.title}, () => {
      successHandler()
    }, () => {
      failHandler()
    })
  }
}

export default TagManagerStore
