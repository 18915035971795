import { makeObservable, observable } from 'mobx';

class BreadCrumbStore {

  constructor() {
    makeObservable(this)
  }


  @observable addrSorts = []

  urlSet = {
    'app' : { to:'/app', text: 'HOME'},
    'reports' : { to:'/', text: 'Reports'},
    'dashboard' : { to:'/reports/dashboard', text: 'Dashboard'},
    'management' : { to:'/app/management/user', text: '관리'},
    'company' : { to:'/app/management/company', text: '업체관리'},
    'case' : { to:'/app/management/case', text: '안건관리'},
    'user' : { to:'/app/management/user', text: '사용자관리'},
    'new' : { to:'/', text: '신규'},
    'voter' : { to:'/', text: '투표자'},
    'vote' : { to:'/', text: '투표'},
    'detail' : { to:'/', text: '상세'},
    'account' : { to:'/account', text: '계정'},
  }


  getBreadcrumbs = (pPath) => {
    return pPath.split("\/")
      .map(path => this.urlSet[path])
      .filter(o => undefined !== o && 'undefined' !== o && null !== o)
  }
}

export default BreadCrumbStore
