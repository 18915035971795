import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const AddressRoute = [
  {
    path: '/app/address',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/address',
        component: lazy(() => import('src/views/address/AddressView'))
      },

    ]
  }
]

export default AddressRoute
