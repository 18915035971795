import React from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { inject, observer } from "mobx-react";

const CommonSnackbar = ({ store }) => {

  const { snackbarStore } = store
  const { show, message, state, closeHandler, duration, closeSnackbar } = snackbarStore

  return (
    <Snackbar
      open={show}
      autoHideDuration={duration}
      onClose={closeSnackbar}
    >
      <Alert
        severity={state}
        onClose={closeSnackbar}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={closeHandler}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default inject('store')(observer(CommonSnackbar))
