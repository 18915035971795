import {Grid} from '@material-ui/core'
import React, {forwardRef} from 'react'
import {DEFAULT_MD, DEFAULT_SPACING, DEFAULT_XS} from 'src/constants/AppConst'

const GridColumn = forwardRef((
  {
    children,
    spacing = DEFAULT_SPACING,
    md = DEFAULT_MD,
    xs = DEFAULT_XS,
    ...rest}, ref) => {
  return (
    <Grid
      item={true}
      md={md}
      xs={xs}
      {...rest}
    >
      {children}
    </Grid>
  )
})

export default GridColumn
