import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'
import _ from 'lodash';
import {receiverType} from "src/views/mail/MailView/enums";


class MailFormStore extends NormalizedStore {
  constructor(root) {
    super('id')
    makeObservable(this)
    this.root = root;
  }

  @observable id = null
  @observable pid = null
  @observable receivers = []
  @observable inputValue = ''
  @observable refValues = []
  @observable inputRefValue = ''
  @observable hiddenRefValues = []
  @observable inputHiddenRefValue = ''
  @observable title = ''
  @observable _messageBody = ''
  @observable importanceYn = 'N'
  @observable separatelySendYn = 'N'
  @observable reserveYn = 'N'
  @observable reserveDt = null
  @observable isToMe = false
  @observable showReceiver = true
  @observable showRef = true
  @observable showHidden = true
  @observable isChanged = false

  @computed get messageBody() {
    return this._messageBody
  }

  @action setId = (param = null) => {
    this.id = param
  }

  @action setPid = (param = null) => {
    this.pid = param
  }

  @action setReceivers = (param = []) => {
    this.receivers = param
  }
  @action setInputValue = (param = '') => {
    this.inputValue = param
  }
  @action setRefValues = (param = []) => {
    this.refValues = param
  }
  @action setInputRefValue = (param = '') => {
    this.inputRefValue = param
  }
  @action setHiddenRefValues = (param = []) => {
    this.hiddenRefValues = param
  }
  @action setHiddenRefInputValue = (param = '') => {
    this.inputHiddenRefValue = param
  }

  @action setTitle = (param = '') => {
    this.title = param
  }

  @action setMessageBody = (param = '') => {
    this._messageBody = param
  }

  @action setImportanceYn = (param = 'N') => {
    this.importanceYn = param
  }

  @action setSeparatelySendYn = (param = 'N') => {
    this.separatelySendYn = param
  }

  @action setReserveYn = (param = 'N') => {
    this.reserveYn = param

    if ('Y' === param && null === this.reserveDt) {
      const tomorrow = new Date(Date.now() + (3600 * 1000 * 24))
      this.reserveDt = new Date(
        `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()} 00:00:00`
      )
    }
  }

  @action setReserveDt = (param = null) => {
    if (undefined !== param && null !== param) {
      this.reserveDt = new Date(param)
      this.reserveYn = 'Y'
    } else {
      this.reserveDt = null
      this.reserveYn = 'N'
    }
  }

  @action setReserveDate = (param = new Date()) => {
    if (null !== this.reserveDt) {
      this.reserveDt.setFullYear(param.getFullYear())
      this.reserveDt.setMonth(param.getMonth())
      this.reserveDt.setDate(param.getDate())
    }
  }

  @computed get reserveHours() {
    return (undefined !== this.reserveDt && null !== this.reserveDt) ? this.reserveDt.getHours() : 0;
  }

  @action setReserveHours = (param = 0) => {
    if (null !== this.reserveDt) {
      this.reserveDt = new Date(this.reserveDt.setHours(param))
    }
  }

  @computed get reserveMinutes() {
    return (undefined !== this.reserveDt && null !== this.reserveDt) ? this.reserveDt.getMinutes() : 0;
  }

  @action setReserveMinutes = (param = 0) => {
    if (null !== this.reserveDt) {
      this.reserveDt = new Date(this.reserveDt.setMinutes(param))
    }
  }

  @action setIsChanged = (param = false) => {
    this.isChanged = param
  }

  @computed get changed() {
    return this.isChanged
  }

  @action clearMailForm = () => {
    this.id = null
    this.pid = null
    this.receivers = []
    this.inputValue = ''
    this.refValues = []
    this.inputRefValue = ''
    this.hiddenRefValues = []
    this.inputHiddenRefValue = ''
    this.title = ''
    this._messageBody = ''
    this.importanceYn = 'N'
    this.separatelySendYn = 'N'
    this.reserveYn = 'N'
    this.reserveDt = null
    this.isChanged = false
    // 나에게 쓰기 초기화
    this.onOffOthers(true)
    this.setIsToMe(false)
  }

  @action setMailInfo = (receivers, refValues, hiddenRefValues, mail, isResend=false) => {
    runInAction(() => {
      this.clearMailForm()
      this.setId(isResend ? null : mail.id)
      this.setPid(mail.pid)
      this.setReceivers(receivers)
      this.setRefValues(refValues)
      this.setHiddenRefValues(hiddenRefValues)
      this.setTitle(mail.title)
      this.setMessageBody(mail.content)
      this.setImportanceYn(mail.importanceYn)
      this.setSeparatelySendYn(mail.separatelySendYn)
      this.setReserveDt(mail.reserveDt)
      this.isChanged = false
    })
  }

  @action setReplyMailInfoByType = (receivers, refValues, mail) => {
    this.setPid(mail.id)
    this.setReceivers(receivers)
    this.setRefValues(refValues)
    this.setTitle(`Re : ${mail.title}`)
    this.setMessageBody(mail.content)
  }

  @action setForwardMailInfo = (title, messageBody) => {
    this.setTitle(`Fw : ${title}`)
    this.setMessageBody(messageBody)
  }

  @action setIsToMe = (param) => {
    this.isToMe = param
  }

  @action setShowReceiver = (param) => this.showReceiver = param

  @action setShowRef = (param) => this.showRef = param

  @action setShowHidden = (param) => this.showHidden = param

  @action onOffOthers = (param) => {
    this.setShowRef(param)
    this.setShowHidden(param)
  }

  getUserInfo = (value, referenceType) => {
    return {
      user: {id: value.id},
      referenceType: referenceType,
      readYn: 'N',
      readDisplayYn: 'N',
      starYn: 'N',
      delYn: 'N'
    };
  }

  getMailParam = () => {
    this.isChanged = false

    const receiver = this.receivers.map(value => this.getUserInfo(value, 'goal'))
    const refReceiver = this.refValues.map(value => this.getUserInfo(value, 'ref'))
    const hiddenRefReceiver = this.hiddenRefValues.map(value => (this.getUserInfo(value, 'hidden')))

    return {
      id: this.id,
      pid: this.pid,
      title: this.title,
      content: this._messageBody,
      receivers: [...receiver, ...refReceiver, ...hiddenRefReceiver],
      importanceYn: this.importanceYn,
      separatelySendYn: this.separatelySendYn,
      reserveDt: 'Y' === this.reserveYn ? this.reserveDt : null,
    }
  }

  haveToConfirmDraftSave = (confirmHandler = () => {}, defaultHandler = () => {}) => {
    if (!(0 === this.receivers.length && 0 === this.refValues.length && 0 === this.hiddenRefValues.length
      && '' === this.title && '' === this._messageBody) && this.isChanged) {
      runInAction(() => {
        confirmHandler()
      })
    } else {
      defaultHandler()
    }
  }

  @computed get allReceivers() {
    const copyList = (baseList, type) => {
      return baseList.map(item => {
        const copied = item
        copied['refType'] = type
        return copied
      }) || []
    }

    const receiverList = copyList(this.receivers, receiverType.RECEIVER)
    const refList = copyList(this.refValues, receiverType.REFERENCE)
    const hiddenList = copyList(this.hiddenRefValues, receiverType.HIDDEN)
    return [...receiverList, ...refList, ...hiddenList]
  }

  classifyGroupReceiver = (receivers) => {
    const groups = {
      goal: [],
      ref: [],
      hidden: [],
    }

    const users = {
      goal: [],
      ref: [],
      hidden: [],
    }

    receivers.forEach((receiver) => {
      if (receiver.orgType) {
        groups[receiver.refType].push({
          id: receiver.id,
          orgType: receiver.orgType,
        })
      } else {
        users[receiver.refType].push(receiver)
      }
    })

    return {
      users: users,
      groups: groups
    }
  }

  @action addMailForm = async (receivers, successHandler = () => {}) => {
    const {users, groups} = this.classifyGroupReceiver(receivers)
    this.receivers = users.goal || []
    this.refValues = users.ref || []
    this.hiddenRefValues = users.hidden || []
    await this.getUsersInGroup(groups)
    successHandler()
  }

  @action getUsersInGroup = (groups) => {
    this.getRequestTemplate('/api/organization/group', groups, (res) => {
      runInAction(() => {
        this.receivers = [...this.receivers, ...res.data.goalUsers]
        this.refValues = [...this.refValues, ...res.data.refUsers]
        this.hiddenRefValues = [...this.hiddenRefValues, ...res.data.hiddenUsers]
      })
    })
  }
}

export default MailFormStore
