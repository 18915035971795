import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'
import {ColumnType} from "src/constants/AppConst";

class OrganizationStore extends NormalizedStore {
  constructor() {
    super('orderNumber')
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['userid'],
      field: 'userid',
      type: ColumnType.STRING
    },
    {
      label: ['name'],
      field: 'username',
      type: ColumnType.STRING,
      default: true
    },
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      width: 70,
    },
    {
      cellId: 'username',
      label: ['name'],
      useSort: true,
    },
    {
      cellId: 'email',
      label: ['email'],
      width: 150,

    },
  ]

  @observable tabs = [
    {
      id: 'department',
      viewType: 'tree',
      label: 'org_chart',
      userType: 'wrapped',
    },
    {
      id: 'tag',
      viewType: 'tree',
      label: 'private_receive_group',
      userType: 'user',
    },
    {
      id: 'public-group',
      viewType: 'tree',
      label: 'public_receive_group',
      userType: 'wrapped',
    },
    {
      id: 'position',
      viewType: 'list',
      label: 'position',
      userType: 'user',
    },
    {
      id: 'grade',
      viewType: 'list',
      label: 'grade',
      userType: 'user',
    },
  ]

  @observable
  openContactPopup = false

  @observable
  _activeTab = this.tabs[0]

  @observable
  _organizationGroup = []

  @observable
  _activeGroupId = ''

  @observable
  _checkedGroups = []

  @observable _contactList = []

  @computed get isOpenContactPopup() {
    return this.openContactPopup
  }

  @computed get activeTab() {
    return this._activeTab
  }

  @computed get organizationGroup() {
    return this._organizationGroup
  }

  @computed get checkedGroups() {
    return this._checkedGroups
  }

  @action setActiveTabByIndex = (index) => {
    this._activeTab = this.tabs[index]
  }

  @action initActiveTab = () => {
    this._activeTab = this.tabs[0]
  }

  @computed get activeGroupId() {
    return this._activeGroupId
  }

  @action setOpenContactPopup = (param) => {
    this.openContactPopup = param
  }

  @action setActiveGroupId = (id) => {
    this._activeGroupId = id
  }

  @action checkGroup = (param) => {
    this._checkedGroups = [...this._checkedGroups, param]
  }

  @action uncheckGroup = (param) => {
    this._checkedGroups = this._checkedGroups.filter((group) => {
      return group.id !== param.id
    })
  }

  @action initCheckedGroup = () => {
    this._checkedGroups = []
  }

  isCheckedGroup = (param) => {
    return this._checkedGroups.some(group => group.id === param.id)
  }

  @action selectTab = (index) => {
    runInAction(() => {
      this.setActiveTabByIndex(index)
      this.initSelectedContents()
      this.clearContent()
      this.initCheckedGroup()
      this.setPage(0)
      this.getGroupList()
    })
  }

  @action selectGroup = (id) => {
    runInAction(() => {
      this.initSelectedContents()
      this.clearContent()
      this.setActiveGroupId(id)
      this.getUsersByGroupId({})
    })
  }

  @computed get contactList() {
    return this._contactList
  }

  @computed get isEmptySelectedContactList() {
    return 0 === this._contactList.length
  }

  @action setContactList = (param = []) => {
    this._contactList = param
  }

  @action removeReceiverHandler = (id) => {
    this._contactList = this._contactList.filter(item => {
      return item.id !== id
    })
  }

  @action clearContactList = () => {
    this._contactList = []
  }

  @action clearContact = () => {
    this.setOpenContactPopup(false)
    this.initActiveTab()
    this.setActiveGroupId('')
    this.initCheckedGroup()
    this.clearContactList()
    this.initSelectedContents()
    this.clearContent()
  }

  @action getGroupList = () => {
    let url = `/api/${this._activeTab.id}`
    if (['tag', 'public-group'].includes(this._activeTab.id)) {
      url = url + '/all'
    }

    this.getRequestTemplate(url, null, (res) => {
      this._organizationGroup = res.data
    })
  }

  @computed get isWrappedUser() {
    return 'wrapped' === this._activeTab.userType
  }

  @action getUsersByGroupId = (params = {}, successHandler = () => {}) => {
    runInAction(() => {
      // todo add position, grade orderNumber sorting
      this.setSort(this.isWrappedUser ? 'orderNumber' : 'id')

      this.sortedRequestTemplate(
        `/api/${this._activeTab.id}/${this._activeGroupId}/users`, params, (res) => {
          this.setContents(res)
        })
    })
  }

  @action addReceiverList = (refType) => {
    let selectedGroups = this._checkedGroups
    selectedGroups.forEach(group => {group['orgType'] = this._activeTab.id})
    let selectedUsers = this.isWrappedUser ?
      this.selectedContents.reduce((result, content) => {
        result.push(content.user)
        return result
      }, [])
      : this.selectedContents

    const filteredContents = [...selectedGroups, ...selectedUsers].filter(selected => {
      return this._contactList.findIndex(receiver => receiver.id === selected.id) === -1
    })

    filteredContents.forEach(selected => {selected['refType'] = refType})
    this._contactList = [...this._contactList, ...filteredContents]
    this.initSelectedContents()
    this.clearContent()
    this.initCheckedGroup()
  }

  @action initContactList = (contactList) => {
    this._contactList = contactList
  }
}

export default OrganizationStore
