import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {inject, observer} from "mobx-react";

const GuestGuard = ({ store, children }) => {

  const { authStore } = store
  const { isValidToken } = authStore;

  if (isValidToken) {
    return <Redirect to="/app/management/mail/all" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default inject('store')(observer(GuestGuard));
