import { action, computed, makeObservable, observable } from 'mobx'
import NormalizedStore from 'src/store/NormalizedStore'
import React from 'react'
import { ColumnType } from 'src/constants/AppConst'

class CompanyStore extends NormalizedStore {

  constructor () {
    super()
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['business_number'],
      field: 'company_no',
      type: ColumnType.STRING,
    },
    {
      label: ['company', 'name'],
      field: 'company_name',
      type: ColumnType.STRING,
      default: true
    },
    {
      label: ['createDt'],
      field: 'reg_dt',
      type: ColumnType.DATETIME,
    }
  ]

  columns = [
    {
      cellId: 'company_no',
      label: ['business_number'],
    },
    {
      cellId: 'company_name',
      label: ['company', 'name'],
      // useSort: true,
    },
    {
      cellId: 'company_tel_no',
      label: ['company', 'telno'],
      // useSort: true,
    },
    {
      cellId: 'corporation_no',
      label: ['corporation', 'number'],
    },
    {
      cellId: 'corporation_name',
      label: ['corporation', 'name'],
    },
    {
      cellId: 'boss_name',
      label: ['boss', 'name'],
    },
    {
      cellId: 'boss_tel_no',
      label: ['boss', 'telno'],
    },
    {
      cellId: 'charger_name',
      label: ['charger', 'name'],
    },
    {
      cellId: 'charger_tel_no',
      label: ['charger', 'telno'],
    },
    {
      cellId: 'reg_dt',
      label: ['createDt'],
      useSort: true,
    },
    {
      cellId: 'actions',
      label: ['detail'],
    },
  ]

  @observable company = {}
  @observable isReadOnly = false
  @observable isDialogCompanyEditForm = false

  @action setCompany = (param) => {
    this.company = param
  }

  @action saveCurrentCompany = (id) => {
    this.setCompany(this.contents.find(content => id === content.id))
  }

  @action setIsReadOnly = (pathParam) => {
    if (!pathParam) {
      this.isReadOnly = false
    } else {
      this.isReadOnly = true
    }
  }

  @action setDialogCompanyEditForm = (param) => {
    this.isDialogCompanyEditForm = param
  }

  @action closeDialogCompanyEditForm = () => {
    this.isDialogCompanyEditForm = false
  }

  @action directSetContents = (param) => {
    this.contents = param
  }

  @action removeContents = (ids) => {
    this.directSetContents(this.contents.filter(content => !ids.find(selected => selected.id === content.id)))
  }

  @computed get selectedContentsIds () {
    return this.selectedContents.map(content => {
      return { id: content.id }
    })
  }

  @computed get formattedContents () {
    const tempContents = this.contents.map(content => {
      content.isContentSelected = false
      return content
    })
    return tempContents
  }

  // http Requests define
  @action getContent = (params = {}) => {
    this.getRequestTemplate(`../company/${params}`, {}, (res) => {
      this.setContents(res.data)
    })
  }

  @action getCompanies = (params = {}, successHandler = () => {}, failHandler = () => {}) => {
    this.sortedRequestTemplate('../company', params,
      (res) => {
        successHandler(res)
      }, () => {
        failHandler()
      })
  }

  @action deletes = (params = {}, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate('../company/deletes', params,
      (res) => {
        successHandler(res)
      },
      (error) => {
        failHandler(error)
      })
  }

  @action updateContents = (param = {}) => {
    this.contents = this.contents.map(content => {
      if (param.id === content.id) {
        return param
      }
      return content
    })
  }

  @action save = (params = {}, successHandler = () => {}, failHandler = () => {}) => {
    this.postRequestTemplate('../company',
      params,
      (res) => successHandler(res),
      (error) => failHandler(error)
    )
  }
}

export default CompanyStore
