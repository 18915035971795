import {lazy} from "react";
import AuthGuard from "../components/AuthGuard";
import DashboardLayout from "../layouts/DashboardLayout";

const UserRoute = [
  {
    path: '/app/user',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/user/:username',
        component: lazy(() => import('src/views/user'))
      },
    ]
  }
]

export default UserRoute
