import { createSlice } from '@reduxjs/toolkit';
// import axios from 'src/utils/axios';
import axios from 'axios';

const initialState = {
  codes: []
};

const slice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    getCodes(state, action) {
      state.codes = action.payload;
    },
    setCodes(state, action) {
      state.codes = action.payload
    }
  }
});

export const reducer = slice.reducer;

export const getCodes = (groupCode) => async (dispatch) => {
  const response = await axios.get(`/api/common/code/${groupCode}`);
  dispatch(slice.actions.getCodes(response.data));
};

export const setCodes = (codes) => (dispatch) => {
  dispatch(slice.actions.setCodes(codes));
};

export default slice;
