import { action, makeObservable, observable } from "mobx";
import { AUTO_HIDE_DURATION, RESULT_SUCCESS } from "src/constants/AppConst";

class SnackbarStore {

  constructor() {
    makeObservable(this)
  }

  @observable show = false

  @observable duration = AUTO_HIDE_DURATION

  @observable state = RESULT_SUCCESS

  @observable closeHandler = () => Promise.resolve()

  @observable message = ''

  @action isShow = (value) => {
    this.show = value
  }

  @action setState = (value) => {
    this.state = value
  }

  @action setCloseHandler = (value) => {
    this.closeHandler = value
  }

  @action setMessage = (value) => {
    this.message = value
  }

  @action setDuration = (value) => {
    this.duration = value
  }

  @action showSnackbar = ({message, state = RESULT_SUCCESS, closeHandler}) => {
    this.setMessage(message)
    this.setState(state)
    this.setCloseHandler(() => {
      closeHandler && closeHandler()
      this.closeSnackbar()
    })
    this.isShow(true)
  }

  @action closeSnackbar = () => {
    this.isShow(false)
  }
}

export default SnackbarStore


