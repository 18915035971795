export const empty = (target) => {
  return undefined === target
    || 'undefined' === target
    || null === target
    || 0 === Object.keys(target).length
}

export const deepCopy = (obj) => {
  if(obj === null || typeof obj !== 'object') {
    return obj;
  }
  const result = Array.isArray(obj) ? [] : {};
  Object.keys(obj).forEach(key => result[key] = deepCopy(obj[key]))
  return result;
}

export const fillEmpty = (target) => {
  return undefined !== target && 'undefined' !== target && null !== target ? target : ''
}
