import {action, makeAutoObservable, makeObservable, observable} from "mobx"
import axios from 'axios'

class SampleStore {

  constructor() {
    makeObservable(this)
  }

  @observable samples

  @action getSamplesAsync(params = {}) {
    axios.get(`/api/sample`, {params})
      .then(res => this.samples = res.data)
  }

  @action async getSamplesSync(params = {}) {
    return axios.get(`/api/sample`, params)
  }

}

export default SampleStore
