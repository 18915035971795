import {Grid} from '@material-ui/core'
import React, {forwardRef} from 'react'
import {DEFAULT_MD, DEFAULT_SPACING, DEFAULT_XS} from 'src/constants/AppConst'

const GridRow = forwardRef((
  {
    children,
    spacing = DEFAULT_SPACING,
    md = DEFAULT_MD,
    xs = DEFAULT_XS,
    ...rest
  }, ref) => {
  return (
    <Grid
      item
      md={12}
      xs={12}
    >
      <Grid
        container
        spacing={spacing}
      >
        <Grid
          item={true}
          md={md}
          xs={xs}
          {...rest}
        >
          <Grid
            container
            spacing={spacing}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

export default GridRow
