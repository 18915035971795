import UserStore from './UserStore';
import { action, computed, makeObservable, observable } from 'mobx';
import { FIRST_PAGE } from '../../constants/AppConst';
import { empty } from 'src/utils/objectUtils'

class UserStoreInfinityScroll extends UserStore {
  constructor() {
    super()
    makeObservable(this)
  }

  @observable continueContents = []
  @observable _selectedUser = {}

  @action changePage = () => {
    if(!this.last && this.countContinueContents && this.countContinueContents !== 0) {
      this.setAddressPage(this.page + 1)
    }
  }

  @computed get countContinueContents() {
    return this.continueContents.length
  }

  @action setContinueContents = (content) => {
    console.debug('setContinueContents', content)
    this.continueContents = content
  }

  @action initContent = () => {
    this.setContinueContents([])
    this.page = FIRST_PAGE
  }

  @action initCurrentSelectedGroup() {
    this.setContinueContents([])
    this.addressPage = FIRST_PAGE
    this.selectedGroup = { id: this.contents[0].id, name: this.contents[0].name }
  }

  @action getUserWithScroll(params) {
    this.getUsersWithAddressGroup(params, (res) => {
      if(null === res
        && null === res.data
        && null === res.data.content ) {
        return
      }
      console.debug('getUserWithScroll : ', res)
      this.setContinueContents(this.continueContents.push({userEntity:  res.data.content }))
    })
  }

  get selectedUser(): {} {
    return this._selectedUser;
  }

  @action setSelectedUser = (value: {}) => {
    this._selectedUser = value;
  }
}

export default UserStoreInfinityScroll