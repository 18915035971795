import { action, makeObservable, observable, computed } from "mobx";

class EditorStore {

  constructor() {
    makeObservable(this)
  }

  @observable _editorRef = null
  @observable _triggerValueChange = false

  @computed get editorRef() {
    return this._editorRef
  }

  @action setChange = () => {
    this._triggerValueChange = !this._triggerValueChange
  }

  @action getEditorMarkDownText = () => {
    return this.editorRef ? this.editorRef.getInstance().getMarkdown() : ''
  }

  @action setEditorMarkDownText = (param) => {
    if(!this._editorRef) {
      return
    }
    this._editorRef.getInstance().setMarkdown(param)
    this._triggerValueChange = !this._triggerValueChange
  }

  @action setEditorRef = (param) => {
    this._editorRef = param
  }
}

export default EditorStore


