import {action, computed, observable, runInAction} from "mobx";
import {DIRECTION_ASC, DIRECTION_DESC, PAGE_SIZE, RESULT_ERROR} from "src/constants/AppConst";
import axios from "axios";
import {paramParser} from "../utils/paramParser";

if (localStorage) {
  const { accessToken } = localStorage
  if (accessToken) {
    axios.defaults.headers.common.Authorization = accessToken
  } else {
    axios.defaults.headers.common.Authorization = ''
  }
  // console.debug('axios.defaults.headers', axios.defaults.headers)
}


class NormalizedStore {
  constructor(initSort) {
    this.defaultSortColumn = 'id'
    if(null !== initSort) {
      this.defaultSortColumn = initSort
    }
  }

  @observable contents = []
  @observable totalElements = 0
  @observable totalPages = 0;
  @observable rowCount = 0
  @observable loading = true
  @observable first = false
  @observable empty = false
  @observable last = false
  @observable numberOfElements = 0;
  @observable content = {}
  @observable sorts = []
  @observable directions = []
  @observable selectedContents = []
  @observable query = ''
  @observable sort = ''
  @observable page = 0
  @observable pageSize = PAGE_SIZE
  @observable sortColumn = {}
  @observable orderBy = ''
  @observable direction
  @observable searchCategories = []
  @observable searchCategory
  @observable categoryType
  @observable searchValue
  @observable searchValueHistory = []



  @action setDefaultSortColumn = (param) => {
    this.defaultSortColumn = param
  }


  setContents = (response) => {
    runInAction(() => {
      const data = response.data
      this.rowCount = this.totalElements = data.totalElements
      this.totalPages = data.totalPages
      this.first = data.first
      this.last = data.last
      this.empty = data.empty
      this.loading = false
      this.contents = data.content
      this.numberOfElements = data.numberOfElements
    })
  }

  setContent = (response) => {
    runInAction(() => {
      this.content = response.data
    })
  }

  @computed get getComputedContent() {
    return this.contents
  }

  @computed get getLoading() {
    return this.loading
  }

  @action clearContent = () => {
    this.contents = []
  }

  @action setPage = (page) => {
    this.page = page
  }

  @action setPageSize = (pageSize) => {
    if (this.pageSize === pageSize) return

    let firstContNum = ((this.page) * this.pageSize) + 1;

    this.page = Math.ceil(firstContNum/ pageSize) - 1;
    this.pageSize = pageSize;
  }

  @action setLoading = (boolFlag) => {
    this.loading = boolFlag
  }

  @action setDirection = (direction) => {
    this.direction = direction
  }

  @action setOrderBy = (orderBy) => {
    this.orderBy = orderBy
  }

  @action setSort = (sortColumn) => {
    this.sort = sortColumn
  }

  @action setSorting = (column) => {
    const columnDirection = this.sortColumn[column.cellId]
    this.setSort(column.cellId)
    this.setOrderBy(column.cellId)
    if (columnDirection) {
      this.direction = this.sortColumn[column.cellId] = (DIRECTION_ASC === columnDirection.toUpperCase() ? DIRECTION_DESC : DIRECTION_ASC).toLowerCase()
    } else {
      this.direction = this.sortColumn[column.cellId] = DIRECTION_DESC.toLowerCase()
    }
  }

  @computed get isSelectedContents() {
    return this.selectedContents.length > 0
  }

  @action addSelectedContent = (content) => {
    this.selectedContents.push(content)
  }

  @action removeSelectedContent = (content) => {
    this.selectedContents = this.selectedContents.filter(selectedContent => {
      return selectedContent !== content
    })
  }

  @action setSelectedContents = (content) => {
    const isSelected = content.isContentSelected

    if (isSelected) {
      this.selectedContents.push(content)
    } else {
      this.selectedContents = this.selectedContents.filter(selectedContent => selectedContent.isContentSelected)
    }
  }

  @action initSelectedContents = () => {
    this.contents.map(content => {
      content.isContentSelected = false
      return content
    })
    this.selectedContents = []
  }

  @action selectAllContents = () => {
    this.selectedContents = this.contents.map(content => {
      content.isContentSelected = true
      return content
    })
  }

  @action handleCheckAllClick = (event) => {
    if (event.target.checked) {
      this.selectAllContents()
      return;
    }
    this.initSelectedContents()
  }

  @computed get selectedContentsIdsArray () {
    return this.selectedContents.map(content => content.id)
  }

  @computed get indeterminateSelected () {
    return this.contents && this.selectedContents.length > 0 && this.selectedContents.length < this.contents.length
  }

  @computed get isAllSelected () {
    return this.contents.length > 0 && this.selectedContents.length === this.contents.length
  }

  isSelected = (id) => {
    return this.selectedContentsIdsArray.includes(id)
  }

  @action handleSelectOneContent = (event, content) => {
    content.isContentSelected = !content.isContentSelected
    this.setSelectedContents(content)
  }

  @action setSearchOptions = (searchOptions) => {
    this.searchOptions = searchOptions
  }

  @action setSearchCategory = (value) => {
    this.searchCategory = value
  }

  @action setCategoryType = (value) => {
    this.categoryType = value
  }

  @action removeContent = (content) => {
    this.contents = this.contents.filter(c => {
      return c !== content
    })
  }

  @action setSearchValue = (param) => {
    this.searchValue = param
  }

  @action addSearchValueHistory = (value) => {
    this.searchValueHistory.push(value)
  }

  @computed get lastSearchValueHistory() {
    if(0 === this.searchValueHistory.length){ return ''}
    return this.searchValueHistory[this.searchValueHistory.length-1]
  }

  isSameSearchHistory = (param) => {
    return '' !== this.lastSearchValueHistory && param === this.lastSearchValueHistory
  }

  sortedRequestTemplate = (url, params, handler, errorHandler =() => {}, finalHandler = () => {}) => {
    this.setLoading(true)
    const sortedParams = {
      ...params,
      rows: this.pageSize,
      page: this.page,
      sortings: [{
        column: this.sort || this.defaultSortColumn,
        direction: (this.direction || DIRECTION_DESC).toUpperCase(),
      }]
    }

    axios.get(`${url}?${paramParser(sortedParams)}`)
      .then(res => {
        runInAction(() => {
          !this.isSameSearchHistory(this.searchValue) && this.setPage(0)
          handler(res)
        })
      })
      .catch(error => {
        if (errorHandler) {
          errorHandler(error)
        } else {
          try {
            const {message} = error['response']['data']
            const result = {status: RESULT_ERROR, message, result: false}
            console.debug('error result', result)
          } catch (e) {
            console.debug('unknown error', e)
          }
        }
      })
      .finally(() => {
        this.addSearchValueHistory(this.searchValue)
        this.setLoading(false)
        finalHandler()
      })
  }

  sortedMaxRequestTemplate = (url, page, pageSize, params, handler, errorHandler, finalHandler = () => {}) => {
    this.setLoading(true)
    const sortedParams = {
      ...params,
      rows: pageSize,
      page: page,
      sortings: [{
        column: this.sort || this.defaultSortColumn,
        direction: (this.direction || DIRECTION_DESC).toUpperCase(),
      }]
    }

    axios.get(`${url}?${paramParser(sortedParams)}`)
        .then(res => {
          handler(res)
        })
        .catch(error => {
          if (errorHandler) {
            errorHandler(error)
          } else {
            try {
              const {message} = error['response']['data']
              const result = {status: RESULT_ERROR, message, result: false}
              console.debug('error result', result)
            } catch (e) {
              console.debug('unknown error', e)
            }
          }
        })
        .finally(() => {
          this.setLoading(false)
          finalHandler()
        })
  }


  postRequestTemplate = (url, params, handler = () => {}, errorHandler = () => {}) => {
    axios.post(`${url}`, params )
      .then(res => {
        handler && handler(res)
      })
      .catch(error => {
        errorHandler && errorHandler(error)
      })
  }

  patchRequestTemplate = (url, params, handler, errorHandler) => {
    axios.patch(`${url}`, params)
      .then(res => {
        handler && handler(res)
      })
      .catch(error => {
        errorHandler && errorHandler(error)
      })
  }

  putRequestTemplate = (url, handler, errorHandler) => {
    axios.put(`${url}`)
      .then(res => {
        handler && handler(res)
      })
      .catch(error => {
        errorHandler && errorHandler(error)
      })
  }

  deleteRequestTemplate = (url, handler, errorHandler) => {
    axios.delete(`${url}`)
      .then(res => {
        handler && handler(res)
      })
      .catch(error => {
        errorHandler && errorHandler(error)
      })
  }

  getRequestTemplate = (url, queryString, handler, errorHandler) => {
    axios.get(`${url}?${paramParser(queryString)}`)
      .then(res => {
        handler(res)
      })
      .catch(res => {
        errorHandler && errorHandler(res)
      })
  }

  @action adjustPage = (deleteCount) => {
    if (this.contents.length - deleteCount === 0) {
      this.setPage(Math.max(0, this.page - 1))
    }
  }
}

export default NormalizedStore
