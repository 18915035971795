import {action, computed, makeObservable, observable} from "mobx";
import NormalizedStore from "src/store/NormalizedStore";
import {ColumnType} from "src/constants/AppConst";

class PublicAddressBookStore extends NormalizedStore {

  constructor() {
    super()
    makeObservable(this)
  }

  searchCategories = [
    {
      label: ['title'],
      field: 'title',
      type: ColumnType.STRING,
    },
    {
      label: ['createDt'],
      field: 'create_dt',
      type: ColumnType.DATETIME,
      default: true
    }
  ]

  columns = [
    {
      cellId: 'id',
      label: ['id'],
      useSort: true,
    },
    {
      cellId: 'create_dt',
      label: ['createDt'],
    },
    {
      cellId: 'actions',
      label: ['detail'],
    },
  ]
}

export default PublicAddressBookStore
